export const getStartDate = (noOfDays) => {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - noOfDays);    
    return startDate;
};

export const getFormattedDate = (dt) => {
    dt = new Date(dt);
    return new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
};

export const getDateTime = (dt) => {
    if(dt){
        dt = new Date(dt);
        return dt;
    }
    else{
        return null;
    }
}

export const getDateTimeString = (dt) => {
    let formattedDatetime = "";
    if(dt){
        let date = new Date(dt);
        formattedDatetime = getFormattedDate(dt);
        formattedDatetime = formattedDatetime + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds()
        return formattedDatetime;
    }
    else{
        return null;
    }
}