import { createSelector } from 'reselect';

export const storeProductsSelector = createSelector(
    state => state.app.get("panelStoreProduct"),
    storeProduct => storeProduct
);

export const selectedStoreProductsSelector = createSelector(
    state => state.app.get("panelSelectedStoreProducts"),
    selectedStoreProducts => selectedStoreProducts ? selectedStoreProducts.toJS() : selectedStoreProducts
);
export const searchTextSelector = createSelector(
    state => state.app.get("panelSearchText"),
    searchText => searchText 
)