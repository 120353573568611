import { REWORK, DEVICE_NAME } from "../../../common/constants/XBOXConstants";
import { YesNoConstants } from '../../../common/constants';
import { SortType,sortValues } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';

export const SKUFilterMapper = {
    [REWORK]: (data, values) => {
        let items = YesNoConstants;

        items.forEach(item => {
            item.count = data.filter(sku => sku.Rework === item.value).length;
        });
        return {
            field: "Rework",
            title: "Transformation",
            items: items
        }
    }
};

export const SKUResultFilterMapper = {
    [REWORK]: (data, values) => {
        return data.filter((item) => values.includes(item.Rework))
    }
};


export const SKUSortMapper = {
    [DEVICE_NAME]: (data) => {
        return {
            key: DEVICE_NAME,
            text: "SKU Part Number",
            default: true,
            defaultSort: SortType.ASC
        }
    }
};

export const SKUResultSortMapper = {
    [DEVICE_NAME]: (data, sortType) => data.sort((a, b) => sortValues(a[DEVICE_NAME],b[DEVICE_NAME],sortType)),
    [REWORK]: (data, sortType) => data.sort((a, b) => sortValues(a[REWORK],b[REWORK],sortType)),
};






