import { take, put, call, apply, select } from 'redux-saga/effects';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.common/utility';
import * as detailsActions from './DeviceProductTransactionDetails.actions';
import ApplicationConfig from '../../../common/utility/applicationConfig';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions';
import { deviceProductStatusInfoMapper } from '../../../common/models';
import { updateSearchResults } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { SEARCH_TYPES } from '../../../common/constants';
import {deviceProductTransactionDetailsSelector} from '../DeviceProductTransactionDetails/DeviceProductTransactionDetails.selectors';
import { showResponseMessage } from '../../../common/actions'

export function* getDeviceProductTransactionDetailsSaga() {
    while (true) {
        try {

            let { transactionId, deviceGroupId, productId } = yield take(detailsActions.GET_DEVICE_PRODUCT_TRANSACTION_DETAILS);
            let transactionDetailsResponse = null;
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/transaction/${transactionId}/${deviceGroupId}/${productId}`, method: 'GET' } ,SagaHandleResponse: { HandleResponse: false } });

            if (response.status === 200) {
                const transactionDetails = yield apply(response, response.json);
                if (transactionDetails) {
                    transactionDetailsResponse = deviceProductStatusInfoMapper(transactionDetails);
                    let transactionList = [...transactionDetailsResponse.DeviceTransactionStatus ];
                    yield put(updateSearchResults(SEARCH_TYPES.SEARCH_DEVICE_PRODUCT_TRANSACTION, transactionList, true));
                }
                yield put(detailsActions.setDeviceProductTransactionDetails(transactionDetailsResponse));
            }
            else{
                yield put(detailsActions.setDeviceProductTransactionDetails(transactionDetailsResponse));
                yield put(showResponseMessage(response));
            }            
           
        } catch (error) {
            yield put(throwException("getDeviceProductTransactionDetailsSaga error: " + error));
            let errorObj = {
                statusText: "Something went wrong. Please contact OEM helpdesk for more assistance."
            }
            yield put(showResponseMessage(errorObj)); 
        }
    }
}

export function* filterDeviceProductTransactionsSaga() {
    while (true) {
        try {
            let { searchText } = yield take(detailsActions.FILTER_DEVICE_PRODUCT_TRANSACTIONS);

            let transactionDetailsResponse = yield select((state) => deviceProductTransactionDetailsSelector(state)); 

            let deviceTransactions = [];
            let transactionList = [...transactionDetailsResponse.DeviceTransactionStatus ];
            if(transactionDetailsResponse && searchText){
                deviceTransactions = transactionList.filter(st =>
                             st.SerialNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                             st.Status.toLowerCase().includes(searchText.toLowerCase())
                    )            
            }  
            else{
                deviceTransactions = transactionList;
            }        
            yield put(updateSearchResults(SEARCH_TYPES.SEARCH_DEVICE_PRODUCT_TRANSACTION, deviceTransactions, true));
        } catch (error) {
            yield put(throwException("getDeviceProductTransactionDetailsSaga error: " + error));
        }
    }
}