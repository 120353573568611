import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.common/utility';
import * as SearchTransactionsAction from './SearchTransaction.actions';
import ApplicationConfig from '../../../common/utility/applicationConfig';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions';
import { transactionMapper, SearchResultData } from '../../../common/models';
import { getFormattedDate } from '../../../common/utility/dateMapper';
import { showResponseMessage } from '../../../common/actions'

const getTransactionData = (transactionitems) => {
    return transactionitems.map((item) => {
        return transactionMapper(item);
    })
}
const getSearchRequest = (searchFilter, searchTransactionCriteria) => {
    let queryParams = "PageNumber=" + searchFilter.page;
    queryParams = queryParams + (searchFilter.pageSize ? "&PageSize=" + searchFilter.pageSize : "");
    if(searchTransactionCriteria){
        queryParams = queryParams + (searchTransactionCriteria.TransactionId.value !== "" ? "&TransactionId=" + searchTransactionCriteria.TransactionId.value : "");
        queryParams = queryParams + (searchTransactionCriteria.TransactionStatus.value !== "" ? "&Status=" + searchTransactionCriteria.TransactionStatus.value : "");
        queryParams = queryParams + (searchTransactionCriteria.StartDate.value ? "&StartDate=" + getFormattedDate(searchTransactionCriteria.StartDate.value) : "");
        queryParams = queryParams + (searchTransactionCriteria.EndDate.value ? "&EndDate=" + getFormattedDate(searchTransactionCriteria.EndDate.value) : "");
    }  
    return queryParams;
}
export function* searchTransactionsActionSaga() {
    while (true) {
        try {
            let { searchFilter, searchTransactionCriteria } = yield take(SearchTransactionsAction.GET_TRANSACTION);

            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/transaction?` + getSearchRequest(searchFilter, searchTransactionCriteria), method: 'GET' }, SagaHandleResponse: { HandleResponse: false } });
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                if (data.TotalCount > 0) {
                    let transactionDetails = new SearchResultData({
                        Records: getTransactionData(data.Records),
                        TotalCount: data.TotalCount,
                        CurrentPage: data.CurrentPage,
                        PageSize: data.PageSize,
                        Token: null
                    });
                    yield put(SearchTransactionsAction.setTransaction(transactionDetails));
                }
                else {
                    let transactionDetails = new SearchResultData({
                        Records: [],
                        TotalCount: 0,
                        CurrentPage: 0,
                        PageSize: 0,
                        Token: null
                    });
                    yield put(SearchTransactionsAction.setTransaction(transactionDetails));
                }

            } else {
                yield put(showResponseMessage(response));
                yield put(SearchTransactionsAction.setTransaction(null));
            }
        } catch (error) {
            yield put(throwException("searchConsoleSaga error: " + error));
            yield put(SearchTransactionsAction.setTransaction(null));
            let errorObj = {
                statusText: "Something went wrong. Please contact OEM helpdesk for more assistance."
            }
            yield put(showResponseMessage(errorObj));            
        }
    }
}


export function* resetTransactionDataSaga() {
    while (true) {
        try {
            yield take(SearchTransactionsAction.RESET_TRANSACTION_DATA);
            yield put(SearchTransactionsAction.setTransaction(null));
            yield put(SearchTransactionsAction.setSearchCriteria(null));
        } catch (error) {
            yield put(throwException("searchConsoleSaga error: " + error));
            yield put(SearchTransactionsAction.setTransaction(null));
            let errorObj = {
                statusText: "Something went wrong. Please contact OEM helpdesk for more assistance."
            }
            yield put(showResponseMessage(errorObj));            
        }
    }
}