
import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { fromJS } from "immutable";
import {
  SET_MANAGEDEVICE_WIZARD_DATA, SET_MANAGEDEVICE_WIZARD_STEP_DATA,
  MANAGEDEVICE_WIZARD_STEP_NEXT, MANAGEDEVICE_WIZARD_STEP_PREVIOUS,
  SET_MANAGEDEVICE_WIZARD_CURRENT_STEP
} from './ManageDeviceAttachments.actions';
import { ManageDeviceConstants } from '../../../device/constants';

export const manageDeviceWizardData = createReducer(null, {
  [SET_MANAGEDEVICE_WIZARD_DATA](state, { wizardData }) {
    loadWizardStatusData(wizardData);
    return fromJS(wizardData);
  },

  [SET_MANAGEDEVICE_WIZARD_STEP_DATA](state, { stepData }) {
    let currentState = state.toJS();
    updateStepData(currentState, stepData);
    return fromJS(currentState);
  },

  [SET_MANAGEDEVICE_WIZARD_CURRENT_STEP](state, { stepData }) {
    let currentState = state.toJS();
    updateCurrentStep(currentState, stepData);
    return fromJS(currentState);
  },

  [MANAGEDEVICE_WIZARD_STEP_NEXT](state, { stepData }) {
    let currentState = state.toJS();
    updateNextStepData(currentState, stepData);
    return fromJS(currentState);
  },

  [MANAGEDEVICE_WIZARD_STEP_PREVIOUS](state, { stepData }) {
    let currentState = state.toJS();
    updatePreviousStepData(currentState, stepData);
    return fromJS(currentState);
  },
});

function loadWizardStatusData(wizardData) {
  if (!wizardData)
    return;
  wizardData.sort((a, b) => a.manageDevice - b.manageDevice);
  if (wizardData.length > 0) {
    wizardData[0].status = ManageDeviceConstants.IN_PROGRESS
  }
}


function updateNextStepData(wizardSteps, stepData) {
  let index = wizardSteps.findIndex(item => item.key === stepData.key);
  if (index >= 0 && (index + 1) < wizardSteps.length) {
    wizardSteps[index] = stepData;
    wizardSteps[index].status = ManageDeviceConstants.COMPLETED;

    let currentStep = wizardSteps[index + 1];
    currentStep.status = ManageDeviceConstants.IN_PROGRESS;
  }
}

function updatePreviousStepData(wizardSteps, stepData) {
  let index = wizardSteps.findIndex(item => item.key === stepData.key);
  if (index > 0) {
    wizardSteps[index] = stepData;
    wizardSteps[index].status = ManageDeviceConstants.NOT_STARTED;

    let currentStep = wizardSteps[index - 1];
    currentStep.status = ManageDeviceConstants.IN_PROGRESS;
  }
}

function updateCurrentStep(wizardSteps, stepData) {
  let index = wizardSteps.findIndex(item => item.key === stepData.key);
  if (index >= 0) {
    let lastStep = wizardSteps.find(item => item.status === ManageDeviceConstants.IN_PROGRESS);
    if (lastStep !== undefined) 
    {
      lastStep.status = lastStep.isStateValid ? ManageDeviceConstants.COMPLETED : ManageDeviceConstants.IN_PROGRESS;
      wizardSteps[index] = stepData;
      wizardSteps[index].status = ManageDeviceConstants.IN_PROGRESS;
    }
  }
}

function updateStepData(wizardSteps, stepData) {
  let index = wizardSteps.findIndex(item => item.key === stepData.key);
  if (index >= 0) {
    wizardSteps[index] = stepData;
  }
}
