import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import * as consoleSelectionStepActions from '../../components/DeviceSelectionStep/DeviceSelectionStep.actions';
import * as consoleSelectionStepSelectors from '../../components/DeviceSelectionStep/DeviceSelectionStep.selectors';
import { getFormattedDate } from '../../../common/utility/dateMapper';
import { TextField, DefaultButton } from 'office-ui-fabric-react';
import { ToggleOptions } from '../../../common/components';
import { DEVICE_TYPE, SEARCH_BY_OPTIONS, IconConstants } from '../../../common/constants';
import "../../components/DeviceFilter/DeviceFilter.scss"
import { announce } from "@ec-oem/ec.oem.oa3.ui.core/components/Announcer/Announcer.actions";

const propTypes = {
  intl: intlShape.isRequired,
  allowItemSelection: PropTypes.bool
}

class DeviceFilter extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      searchBy: this.props.searchBy,
      inputData: this.updateInitialData(this.getDefaultData(), this.props.searchCriteria),
      isAdvanceSearch: this.props.isAdvanceSearch
    }
  }

  updateInitialData = (inputData, searchCriteria) => {
    if (searchCriteria) {
      inputData.SearchBy = searchCriteria.SearchBy ? searchCriteria.SearchBy : inputData.SearchBy;
      inputData.SKUNumber = searchCriteria.SKUNumber ? searchCriteria.SKUNumber : inputData.SKUNumber;
      inputData.SerialNumber = searchCriteria.SerialNumber ? searchCriteria.SerialNumber : inputData.SerialNumber;
      inputData.StoreProducts = searchCriteria.StoreProducts ? searchCriteria.StoreProducts : inputData.StoreProducts;
      inputData.BuildDate = searchCriteria.BuildDate ? searchCriteria.BuildDate : inputData.BuildDate;
      inputData.Description = searchCriteria.Description ? searchCriteria.Description : inputData.Description;
    }
    return inputData;
  }

  getDefaultData = () => {
    let searchBy = this.state && this.state.searchBy ? this.state.searchBy : DEVICE_TYPE.SKU;
    return {
      SearchBy: { value: searchBy, text: searchBy, error: '' },
      SKUNumber: { value: '', text: '', error: '' },
      SerialNumber: { value: '', text: '', error: '' },
      StoreProducts: { value: '', text: '', error: '' },
      BuildDate: { value: null, text: '', error: '' },
      Description: { value: '', text: '', error: '' }
    }
  }

  getSearchByDetails = () => {
    if (this.state.searchBy === DEVICE_TYPE.SKU) {
      return {
        id: 'selectConsole.skuNumber',
        defaultMessage: "SKU Part Number",
        name: "SKUNumber",
        placeholderId: 'selectConsole.skuNumber',
        placeholderDefaultMessage: 'SKU Part Number',
        field: 'SKUNumber',
        ariaLabel: 'Please enter SKU Part Number here',
        value: this.state.inputData.SKUNumber.value,
        error: this.state.inputData.SKUNumber.error
      }
    }
    else {
      return {
        id: 'selectConsole.serialNumber',
        defaultMessage: "Serial Number",
        name: "SerialNumber",
        placeholderId: 'selectConsole.serialNumber',
        placeholderDefaultMessage: 'Serial Number',
        field: 'SerialNumber',
        ariaLabel: 'Please enter Serial number here',
        value: this.state.inputData.SerialNumber.value,
        error: this.state.inputData.SerialNumber.error
      }
    }
  }

  getRequestParams = (isAdvanceSearch, searchCriteria) => {
    let request = {
      SearchBy: (searchCriteria.SearchBy && searchCriteria.SearchBy.value) ? searchCriteria.SearchBy.value : DEVICE_TYPE.SKU,
      SKUNumber: (searchCriteria.SKUNumber && searchCriteria.SKUNumber.value) ? searchCriteria.SKUNumber.value : '',
      SerialNumber: (searchCriteria.SerialNumber && searchCriteria.SerialNumber.value) ? searchCriteria.SerialNumber.value : ''
    };

    if (isAdvanceSearch) {
      if (searchCriteria.StoreProducts && searchCriteria.StoreProducts.value) {
        request.StoreProducts = searchCriteria.StoreProducts.value;
      }
      if (searchCriteria.Description && searchCriteria.Description.value) {
        request.Description = searchCriteria.Description.value;
      }
      if (searchCriteria.BuildDate && searchCriteria.BuildDate.value) {
        request.BuildDate = searchCriteria.BuildDate && getFormattedDate(searchCriteria.BuildDate.value);
      }
    }
    return request;
  }

  handleSearchBy = (searchBy) => {
    if (this.state.searchBy !== searchBy) {

      this.props.actions.destroyStoreData();

      let search = {
        field: 'SearchBy',
        value: searchBy,
        text: searchBy,
        error: ''
      };

      this.props.actions.setSearchCriteria({ isAdvanceSearch: false, searchCriteria: { SearchBy: search } });

      this.setState({
        searchBy: searchBy,
        inputData: this.updateInitialData(this.getDefaultData(), null),
        isAdvanceSearch: false,
      })

      this.setFields([search]);
    }
  }

  setFields = (fields) => {
    let inputFields = this.state.inputData;
    fields.forEach(element => {
      if (element.error) {
        inputFields[element.field].error = element.error;
        element.value = '';
        element.text = '';
      }
      else {
        inputFields[element.field].error = '';
      }
      inputFields[element.field].value = element.value;
      inputFields[element.field].text = element.text;
    });

    this.setState({
      inputData: inputFields
    });
  }

  toggleAdvanceOption = () => {
    let isAdvanceSearch = !this.state.isAdvanceSearch;
    let searchCriteria = this.state.inputData;
    this.setState({
      isAdvanceSearch: isAdvanceSearch
    })

    this.props.actions.setSearchCriteria({ isAdvanceSearch: isAdvanceSearch, searchCriteria: searchCriteria });
  }

  getRequiredFields = () => {
    let requiredItems = this.state.searchBy === DEVICE_TYPE.SKU ? ['SKUNumber'] : ['SerialNumber'];
    return requiredItems;
  }

  isStateValid = () => {
    let valid = true;
    if (this.state.inputData) {
      let inputData = this.state.inputData;
      let requiredFields = this.getRequiredFields();
      let noOfErrors = 0;
      Object.keys(inputData).forEach(key => {
        if (requiredFields.indexOf(key) > -1) {
          valid = (valid && inputData[key] && inputData[key].value) ? true : false;
          if (!(inputData[key] && inputData[key].value)) {
            inputData[key].value = '';
            inputData[key].text = '';
            inputData[key].error = inputData[key].error ? inputData[key].error :
              this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" });
            noOfErrors++;
          }
        }
      });

      if (inputData['SKUNumber'] && inputData['SKUNumber'].value) {
        if (inputData['SKUNumber'].value.length > 0 && inputData['SKUNumber'].value.length < 3) {
          valid = false;
          noOfErrors++;
          inputData['SKUNumber'].value = '';
          inputData['SKUNumber'].text = '';
          inputData['SKUNumber'].error = inputData['SKUNumber'].error ? inputData['SKUNumber'].error :
            this.props.intl.formatMessage({ id: 'selectConsole.skuNumberValidationMessage', defaultMessage: "Please enter minimum 3 characters" });
        }
      }

      if (inputData['SerialNumber'] && inputData['SerialNumber'].value) {
        if (inputData['SerialNumber'].value.length > 0 && inputData['SerialNumber'].value.length > 12) {
          valid = false;
          noOfErrors++;
          inputData['SerialNumber'].value = '';
          inputData['SerialNumber'].text = '';
          inputData['SerialNumber'].error = inputData['SerialNumber'].error ? inputData['SerialNumber'].error :
            this.props.intl.formatMessage({ id: 'selectConsole.serialNumberValidationMessage', defaultMessage: "Please enter maximum 12 characters" });
        }
      }

      if (noOfErrors > 0) {
        this.setState({
          inputData: inputData
        });
      }
    }
    else {
      valid = false;
    }
    return valid;
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (!this.state.isAdvanceSearch)
        this.onSearchClick();
    }
  }

  onSearchClick = () => {
    if (!this.isStateValid()) {
      return;
    }
    else {
      let isAdvanceSearch = this.state.isAdvanceSearch;
      let searchCriteria = this.state.inputData;
      this.props.actions.setSearchCriteria({ isAdvanceSearch: isAdvanceSearch, searchCriteria: searchCriteria });
      if (searchCriteria.SearchBy.value === DEVICE_TYPE.SKU) {
        this.props.actions.getSearchBySKUResult(this.getRequestParams(isAdvanceSearch, searchCriteria), this.props.allowItemSelection);
      }
      else {
        this.props.actions.getSearchByConsoleResult(this.getRequestParams(isAdvanceSearch, searchCriteria), this.props.allowItemSelection);
      }
    }
  }

  onClearClick = () => {
    this.setState({
      inputData: this.getDefaultData()
    });
    this.props.announce({ message: "Search Input is cleared." });
  }

  selectDate = (field, value) => {
    let inputData = this.state.inputData;
    inputData[field].value = value;
    inputData[field].error = '';
    this.setState([{ inputData: inputData }])
  }

  render() {

    let details = this.getSearchByDetails();
    return (
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 select-console-criteria removepadding">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 removepadding">
          <div className="ms-Grid-col ms-sm12 ms-md5 ms-lg4 removepadding console-filter paddRgt10 removepaddRgt10">
            <ToggleOptions className="toggle-option"
              caption={this.props.intl.formatMessage({ id: 'selectConsole.deviceType', defaultMessage: 'Device Type' })}
              toggleOptions={SEARCH_BY_OPTIONS}
              defaultSelectedKey={SEARCH_BY_OPTIONS.indexOf(this.state.searchBy)}
              handleToggle={this.handleSearchBy}>
            </ToggleOptions>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md7 ms-lg8 removepadding">
            <TextField label={this.props.intl.formatMessage({ id: details.id, defaultMessage: details.defaultMessage })}
              id={"SKUSerialNumber"}
              name={details.name}
              placeholder={this.props.intl.formatMessage({ id: details.placeholderId, defaultMessage: details.placeholderDefaultMessage })}
              required={true}
              value={details.value}
              onKeyDown={this.handleKeyPress}
              onChange={(event, value) => this.setFields(
                [{
                  field: details.field,
                  value: value,
                  text: value,
                  error: this.state.isAdvanceSearch ? "" :
                    (value ? '' : this.props.intl.formatMessage({ id: 'app.requiredfieldmessage', defaultMessage: "Required Field" }))
                }
                ])}
              errorMessage={details.error}
              ariaLabel={details.ariaLabel}
            />
          </div>
          {
            this.state.searchBy === DEVICE_TYPE.SKU &&
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 removepadding">
              <div className="float-right-content advance-search-option removepadding">
                <button className="advance-search-button" onClick={this.toggleAdvanceOption} aria-live="assertive" aria-label=
                  {this.state.isAdvanceSearch ?
                    this.props.intl.formatMessage({ id: 'selectConsole.hideAdvancedOptions', defaultMessage: 'Hide Advanced Options' }) :
                    this.props.intl.formatMessage({ id: 'selectConsole.showAdvancedOptions', defaultMessage: 'Show Advanced Options' })}>
                  {
                    this.state.isAdvanceSearch ?
                      this.props.intl.formatMessage({ id: 'selectConsole.hideAdvancedOptions', defaultMessage: 'Hide Advanced Options' }) :
                      this.props.intl.formatMessage({ id: 'selectConsole.showAdvancedOptions', defaultMessage: 'Show Advanced Options' })
                  }
                </button>
              </div>
            </div>
          }
        </div>
        {
          this.state.searchBy === DEVICE_TYPE.SKU && this.state.isAdvanceSearch &&
          <div className="advance-option-inputs removepadding">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 removepadding filterpanel">
              <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4 removepadding paddRgt10 removepaddRgt10">
                <TextField label={this.props.intl.formatMessage({ id: 'selectConsole.storeProducts', defaultMessage: 'Digital Attach Product' })}
                  id={'StoreProducts'}
                  name={'StoreProducts'}
                  placeholder={this.props.intl.formatMessage({ id: 'selectConsole.storeProducts', defaultMessage: 'Digital Attach Product' })}
                  required={false}
                  value={this.state.inputData.StoreProducts.value}
                  onKeyDown={this.handleKeyPress}
                  onChange={(event, value) => this.setFields(
                    [{
                      field: 'StoreProducts',
                      value: value,
                      text: value,
                      error: ''
                    }
                    ])}
                  errorMessage={this.state.inputData.StoreProducts.error}
                  ariaLabel={'Please enter Store products here'}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8 removepadding">
                <TextField label={this.props.intl.formatMessage({ id: 'selectConsole.description', defaultMessage: 'Description' })}
                  id={'Description'}
                  name={'Description'}
                  placeholder={this.props.intl.formatMessage({ id: 'selectConsole.description', defaultMessage: 'Description' })}
                  required={false}
                  value={this.state.inputData.Description.value}
                  onKeyDown={this.handleKeyPress}
                  onChange={(event, value) => this.setFields(
                    [{
                      field: 'Description',
                      value: value,
                      text: value,
                      error: ''
                    }
                    ])}
                  errorMessage={this.state.inputData.Description.error}
                  ariaLabel={'Please enter Description here'}
                />
              </div>
            </div>
          </div>
        }
        <div className="action-buttons ms-Grid-col ms-sm12 ms-md12 ms-lg12 removepadding ms-textAlignRight">
          <DefaultButton
            className="spacing iconRight"
            id="clearButton"
            primary={true}
            disabled={false}
            text={this.props.intl.formatMessage({ id: 'selectConsole.clear', defaultMessage: "Clear" })}
            iconProps={{ iconName: IconConstants.Cancel }}
            onClick={this.onClearClick}
            allowDisabledFocus={true} />
          <DefaultButton
            className="iconRight"
            id="searchButton"
            primary={true}
            disabled={false}
            text={this.props.intl.formatMessage({ id: 'selectConsole.search', defaultMessage: "Search" })}
            iconProps={{ iconName: IconConstants.Search }}
            onClick={this.onSearchClick}
            allowDisabledFocus={true} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let initialSearchState = consoleSelectionStepSelectors.searchCriteriaSelector(state);

  let searchBy = DEVICE_TYPE.SKU;
  if (initialSearchState && initialSearchState.searchCriteria && initialSearchState.searchCriteria.SearchBy) {
    searchBy = initialSearchState.searchCriteria.SearchBy.value;
  }

  let isAdvanceSearch = false;
  let searchCriteria = null;

  if (initialSearchState) {
    isAdvanceSearch = initialSearchState.isAdvanceSearch;
    searchCriteria = initialSearchState.searchCriteria;
  }
  return {
    searchBy: searchBy,
    isAdvanceSearch: isAdvanceSearch,
    searchCriteria: searchCriteria,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(consoleSelectionStepActions, dispatch),
    announce: bindActionCreators(announce, dispatch)
  }
}

DeviceFilter.propTypes = propTypes;

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeviceFilter));