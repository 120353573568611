'use strict';
import { Product } from './Product';
import { transactionStatusSummaryMapper } from './TransactionStatusSummary';
import { deviceTransactionStatusMapper } from './DeviceTransactionStatus';

export class ProductTransactionInfo extends Product {
    constructor(product, transactionStatusSummary, deviceTransactionStatus) {
        super(product);
        this.TransactionStatusSummary = transactionStatusSummary ? transactionStatusSummary : [];
        this.DeviceTransactionStatus = deviceTransactionStatus ? deviceTransactionStatus : []

    }
}

export const productTransactionsInfoMapper = (productTransactions) => {
    let productTransactionInfo = [];
    productTransactionInfo = productTransactions.map((productTransaction, index) => {
        return new ProductTransactionInfo(productTransaction,
            transactionStatusSummaryMapper(productTransaction.TransactionStatusSummary),
            deviceTransactionStatusMapper(productTransaction.DeviceTransactionStatus)
        )
    })
    return productTransactionInfo;
}