import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Modal, IconButton, DefaultButton } from 'office-ui-fabric-react';
import PropTypes from 'prop-types';
import { IconConstants } from '../../../common/constants'
import "./ConfirmationPopup.scss";

const propTypes = {
    isOpen: PropTypes.bool,
    onProceed: PropTypes.func,
    onDismiss: PropTypes.func,
    confirmationTitle: PropTypes.string,
    confirmationMessage: PropTypes.string
}

const ConfirmationPopup = (props) => {

    return (
        <Modal
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            isBlocking={true}
            className="confirmation-popup">
            <div className="ms-Grid popup-grid">
                <div className="d-flex ms-Grid-row ms-sm12 ms-md12 ms-lg12 ms-xl12">
                    <div className="details-header" aria-live="assertive">
                        {props.confirmationTitle}
                    </div>
                    <IconButton id="closeButton" iconProps={{ iconName: IconConstants.ChromeClose }}
                        title="Close"
                        ariaLabel="Close Details"
                        className="details-close"
                        onClick={props.onDismiss} />
                </div>
                <div className="ms-Grid-row ms-sm12 ms-md12 ms-lg12 ms-xl12">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 main-message">
                        {props.confirmationMessage}
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 removepadding confirmation-message">
                        Do you wish to proceed?
                            </div>

                </div>

                <div className="ms-Grid-col ms-smPush1 ms-sm4 ms-mdPush2 ms-md2 ms-lgPush3 ms-lg2 ms-xlPush3 ms-xl2">
                    <DefaultButton id="cancelButton" className="popup-cancel spacing iconLeft"
                        primary={true}
                        text={props.intl.formatMessage({ id: 'app.cancel', defaultMessage: "CANCEL" })}
                        iconProps={{ iconName: IconConstants.Cancel }}
                        onClick={props.onDismiss}
                    />
                </div>

                <div className="ms-Grid-col ms-smPush2 ms-sm4 ms-mdPush4 ms-md2 ms-lgPush4 ms-lg2 ms-xlPush4 ms-xl2">
                    <DefaultButton
                        id="proceedButton"
                        className="popup-proceed iconRight"
                        primary={true}
                        text={props.intl.formatMessage({ id: 'app.proceed', defaultMessage: "PROCEED" })}
                        iconProps={{ iconName: IconConstants.Accept }}
                        onClick={props.onProceed}
                    />
                </div>
            </div>
        </Modal>
    )
}

ConfirmationPopup.propTypes = propTypes
export default injectIntl(ConfirmationPopup); 