'use strict';

export class TransactionStatusSummary {
    constructor(devicetransactionInfo) {
        this.Status = devicetransactionInfo.Status
        this.Count = devicetransactionInfo.Count
    }
}

export const transactionStatusSummaryMapper = (statusSummary) => {
    let transactionStatusSummary = []
    if (statusSummary) {
        statusSummary.map((transactionStatus) => {
            transactionStatusSummary.push(new TransactionStatusSummary(transactionStatus))
        })
    }
    return transactionStatusSummary;
}