import { Label, Pivot, PivotItem, PivotLinkFormat, PivotLinkSize } from 'office-ui-fabric-react';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const propTypes = {
    toggleOptions: PropTypes.array,
    caption: PropTypes.string,
    className: PropTypes.string,
    defaultSelectedKey: PropTypes.number,
    handleToggle: PropTypes.func
}

class ToggleOptions extends React.Component {
    onLinkClick = (item) => {
        let option = this.props.toggleOptions[item.props.itemKey];
        this.props.handleToggle(option);
    }

    render() {
        return (
            <div>
                <Label>{this.props.caption}</Label>
                <Pivot linkSize={PivotLinkSize.normal}
                    linkFormat={PivotLinkFormat.tabs}
                    className={this.props.className}
                    defaultSelectedKey={this.props.defaultSelectedKey}
                    onLinkClick={this.onLinkClick}>
                    {this.props.toggleOptions.map((option, index) =>
                        <PivotItem key={index} headerText={option}
                            itemKey={index}
                        >
                        </PivotItem>)}
                </Pivot>
            </div>
        );
    }
}

ToggleOptions.propTypes = propTypes
export default injectIntl(ToggleOptions);