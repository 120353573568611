import { take, put, call, apply } from 'redux-saga/effects';
import * as DetailsActions from './TransactionDetails.actions';
import ApplicationConfig from '../../../common/utility/applicationConfig';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions';
import { transactionMapper } from '../../../common/models';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.common/utility';
import { showResponseMessage } from '../../../common/actions';

export function* getTransactionDetailsSaga() {
    while (true) {
        try {
            let { transactionId } = yield take(DetailsActions.GET_TRANSACTION_DETAILS);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/transaction/` + transactionId, method: 'GET' }, SagaHandleResponse: { HandleResponse: false } });
            if (response.status === 200) {
                const details = yield apply(response, response.json);
                let transactionDetails = transactionMapper(details);
                yield put(DetailsActions.setTransactionDetails(transactionDetails));
            }
            else {
                yield put(DetailsActions.setTransactionDetails(null));
                yield put(showResponseMessage(response));
            }

        } catch (error) {
            yield put(throwException("getTransactionDetailsSaga error: " + error));
            let errorObj = {
                statusText: "Something went wrong. Please contact OEM helpdesk for more assistance."
            }
            yield put(showResponseMessage(errorObj)); 
        }
    }
}