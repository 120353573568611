import React, { Component } from 'react';
import './ManageDeviceAttachments.scss'
import { ManageDeviceConstants } from '../../constants'
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as manageDeviceActions from './ManageDeviceAttachments.actions';
import { bindActionCreators } from 'redux';
import { manageDeviceWizardDataSelector } from './ManageDeviceAttachments.selectors';
import { WizardNav, FeatureAlerts, WizardStepFactory } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { DeviceSelectionStep } from '../DeviceSelectionStep';
import { DeviceProductSelectionStep } from '../DeviceProductSelectionStep';
import { setPageTitle } from '@ec-oem/ec.oem.oa3.ui.common/utility/pageTitle';
import { AlertsFeature } from "@ec-oem/ec.oem.oa3.ui.common/constants/CoreConstants";
import { PageTitles, TabIndexes, XBOXApplication } from '../../../common/constants';

class ManageDeviceAttachments extends Component {
    getStepComponentMap = () => {
        return {
            SELECT_CONSOLE: DeviceSelectionStep,
            SELECT_STORE_PRODUCTS: DeviceProductSelectionStep
        }
    }

    componentWillMount() {
        this.resetManageDeviceData();
    }

    componentDidMount() {
        setPageTitle(PageTitles.MANAGE_DEVICE, XBOXApplication);
    }

    componentWillUnmount() {
        this.resetManageDeviceData();
    }

    resetManageDeviceData = () => {
        if (this.props.actions) {
            this.props.actions.getManageDeviceWizardData();
        }
    }

    getCurrentStep = (steps) => {
        let step = steps && steps.length > 0 && steps[steps.length - 1];
        if (steps && steps.find(item => item.status === ManageDeviceConstants.IN_PROGRESS)) {
            step = steps.find(item => item.status === ManageDeviceConstants.IN_PROGRESS);
        }
        return step;
    }

    onSubmitClick = (data) => {
        this.props.actions.manageDeviceWizardStepNext(data);
    }

    onItemClick = (item) => {
        if (item.status === ManageDeviceConstants.IN_PROGRESS || item.status === ManageDeviceConstants.COMPLETED) {           
                this.props.actions.setManageDeviceWizardCurrentStep(item);           
        }
    }

    render() {
        return (
            <div className="container">
                <div className="page-header">
                    <h1 className="mainHeading" tabIndex={TabIndexes.InOrder}>{this.props.intl.formatMessage({ id: 'manageDevice.title', defaultMessage: 'Manage Device Attachments' })}</h1>                    
                </div>
                {this.props.wizardData &&
                    <div className="create-detach-wizard">
                        <div className="create-detach-nav-items">
                            <WizardNav tabIndex={TabIndexes.OutOfOrder} items={this.props.wizardData}></WizardNav>
                        </div>
                        <FeatureAlerts timeOut={5000} feature={AlertsFeature.XBOX_ALERTS} />
                        <div className="create-detach-steps">
                            {
                                <WizardStepFactory stepComponentMap={this.getStepComponentMap()} currentStep={this.getCurrentStep(this.props.wizardData)}
                                    onPreviousClick={this.onPreviousClick} onSubmitClick={this.onSubmitClick} />
                            } 
                        </div>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    let wizardDataInfo = manageDeviceWizardDataSelector(state);
    return {
        wizardData: wizardDataInfo,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: bindActionCreators(manageDeviceActions, dispatch),
    }
}

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageDeviceAttachments))