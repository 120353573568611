import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { DetailsList, Selection, GroupedList, FocusZone, SelectionMode, CheckboxVisibility } from 'office-ui-fabric-react';
import { TabIndexes } from '@ec-oem/ec.oem.oa3.ui.common/constants';
import { DEVICE_TYPE } from '../../../common/constants';
import "./DeviceSelection.scss";
import ReactDOM from 'react-dom';
const propTypes = {
    selectedDevices: PropTypes.array,
    onItemClick: PropTypes.func
}

class DeviceSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDevices: this.props.selectedDevices,
            isCollapsed: true,
            selection: new Selection(
                {
                    onSelectionChanged: () => {
                        this.setState({
                            selectionDetails: this.onSelectionChange()
                        });
                    }
                })
        }
    }

    componentWillMount() {
        let devices = this.state.selectedDevices;
        if (devices && devices.length > 0) {
            let i=0;
            devices.forEach(device => {
                device.key = i++;
                device.AttachmentsCount = device.Attachments ? device.Attachments.length : 0;
                device.Transformation = device.Rework ? 'Yes' : 'No'
            });
            this.setState({
                selectedDevices: devices
            });
        }
        this.state.selection.setItems(devices);
        this.state.selection.setAllSelected(true);
    }

    componentDidMount() {
        this.setState({
            isCollapsed: true
        });
    }

    getColumns = () => {
        let devices = this.state.selectedDevices;
        let columnHeaders = [];
        if (devices && devices.length > 0) {
            if (devices[0].DeviceType === DEVICE_TYPE.SKU) {
                columnHeaders = [
                    {
                        key: 'DeviceName',
                        name: 'SKU Part Number',
                        fieldName: 'DeviceName',
                        minWidth: 40,
                        maxWidth: 200,
                    },
                    {
                        key: 'Transformation',
                        name: 'Transformation',
                        fieldName: 'Transformation',
                        minWidth: 40,
                        maxWidth: 200
                    },
                    {
                        key: 'Attachments',
                        name: 'Attachments',
                        fieldName: 'AttachmentsCount',
                        minWidth: 30,
                        maxWidth: 200
                    }
                ];
            }
            else {
                columnHeaders = [
                    {
                        key: 'SerialNumber',
                        name: 'Device Serial Number',
                        fieldName: 'SerialNumber',
                        minWidth: 400,
                        maxWidth: 400,
                    }
                ]
            }
        }
        return columnHeaders;
    }

    onSelectionChange = () => {
        this.setState({
            isCollapsed: false
        });
        this.props.onItemClick(this.state.selection.getSelection());
    }

    getGroupData = () => {
        if (this.state.selectedDevices) {
            return [
                {
                    key: 'devices',
                    name: 'Devices',
                    startIndex: 0,
                    count: this.state.selectedDevices.length,
                    isCollapsed: this.state.isCollapsed
                }
            ]
        }
        return null;
    }

    onRenderCell = (nestingDepth, items, itemIndex) => {
        return (
            <DetailsList
               items={items}
                columns={this.getColumns()}
                selection={this.state.selection}
                selectionMode={SelectionMode.multiple}
                itemIndex={itemIndex}
                setKey="set"
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionZoneProps={{ selection: this.state.selection, isSelectedOnFocus: false }}
                enterModalSelectionOnTouch={true}
            />            
        )
    }

    onRenderHeader = (props) => {
        const onToggleSelectGroup = () => {
            props.onToggleCollapse(props.group);
        };
        const setFocusOnGroup=()=>{
            
            let containerDiv = ReactDOM.findDOMNode(this.refs.detailsListRef);
        
            if (containerDiv != null) {
              let [containerLst] = containerDiv.getElementsByClassName('ms-DetailsRow-check--isHeader');
              if (containerLst != null) {
              containerLst.tabIndex = TabIndexes.InOrder;
              containerLst.focus();
              }
            }
        };
        return (
            <div tabIndex={TabIndexes.InOrder}
                className="device-selection-section"
                onKeyDown={(e) => { if (e.keyCode === 13 || e.keyCode === 32) onToggleSelectGroup()
                else if (e.keyCode === 40 ) setFocusOnGroup() }}
                onClick={onToggleSelectGroup}>
                {props.group.isCollapsed ? <i className="ms-Icon ms-Icon--ChevronRightMed ms-slideUpIn10" aria-live="assertive" aria-label="List Collapsed"></i> : <i className="ms-Icon ms-Icon--ChevronUp ms-slideUpIn10" aria-live="assertive" aria-label="List Expanded"></i>}
                <span className="device-selection-text">{this.state.selectedDevices[0].DeviceType == DEVICE_TYPE.SKU ? "SKUs ": "Devices " }: {this.state.selection.getSelectedCount()} </span>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.state.selectedDevices &&
                    <FocusZone>
                        <GroupedList
                            ref="detailsListRef"
                            items={[this.state.selectedDevices]}
                            onRenderCell={this.onRenderCell}
                            columns={this.getColumns()}
                            groups={this.getGroupData()}
                            groupProps={{ onRenderHeader: this.onRenderHeader }}
                            selectionPreservedOnEmptyClick={true}
                        />
                    </FocusZone>
                }
            </div>
        );
    }
}

DeviceSelection.propTypes = propTypes;
export default injectIntl(DeviceSelection);