import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SKUCard, ConsoleCard, StoreProductCard } from "../../../common/components";
import { Label } from 'office-ui-fabric-react';
import { Iterator } from '@ec-oem/ec.oem.oa3.ui.core/components/Iterator';
import { Accordion, AccordionHeader, AccordionBody } from '@ec-oem/ec.oem.oa3.ui.core/components/Accordion';
import { DEVICE_TYPE, TRANSACTION_TYPE } from '../../../common/constants';

import './DeviceProductDetails.scss';

const propTypes = {
    DeviceType: PropTypes.string,
    Device: PropTypes.object,    
    disableSelection: PropTypes.bool,
    transactionRecordId: PropTypes.string,
    hideAttachments: PropTypes.bool,
    isExpand: PropTypes.bool,
    ProductTransactions: PropTypes.array
}

const onAccordionToggle = (props, toggleStatus) => {
    props.onExpandCollapse && props.onExpandCollapse(props.Device && props.Device.id, toggleStatus);
}

const DeviceProductDetails = (props) => {
    return (
        <div className="device-product-details">
            <Accordion id="device-product-details-accordion" isOpen={props.isExpand} onAccordionToggle={(toggleStatus) => onAccordionToggle(props, toggleStatus)}>
                <AccordionHeader>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl4 sku-console-section-heading">
                        { props.DeviceType === DEVICE_TYPE.SKU ? props.Device.DeviceName : props.Device.SerialNumber}
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8 ms-xl8 store-count-section-heading">
                        <span>Attach: <span className="label-value attach-count">{props.ProductTransactions ? props.ProductTransactions.filter(item => item.OperationType === TRANSACTION_TYPE[1]).length : 0} </span></span>
                        <span>Deatch: <span className="label-value deatch-count">{props.ProductTransactions ? props.ProductTransactions.filter(item => item.OperationType === TRANSACTION_TYPE[2]).length : 0} </span></span>
                    </div>
                </AccordionHeader>
                <AccordionBody>
                    <div className={"ms-Grid ms-sm12 ms-md12 console-product-mapping selectiondatagrid " + (props.ProductTransactions[0].TransactionStatusSummary ? "onDetach-BundleConfirmation" : "")}>
                        <div className="sku-console-heading-each-row ms-Grid-col ms-sm12 ms-md12 pagesubHeading border-Bottom paddTop10 paddBtm5 paddLftRgt0 ">
                            {props.DeviceType === 'SKU' ? 'SKU' : 'Console'} Details
                        </div>
                        <div className="sku-console-details">
                            {props.DeviceType === DEVICE_TYPE.SKU ?
                                <SKUCard hideAttachments={props.hideAttachments} index={props.Device.id} disableSelection={true} {...props.Device} > </SKUCard> :
                                <ConsoleCard hideAttachments={props.hideAttachments} index={props.Device.id} disableSelection={true} {...props.Device} > </ConsoleCard>
                            }
                        </div>
                        <div className="store-product-heading-each-row removepadding ms-Grid-col ms-sm12 ms-md12  pagesubHeading border-Bottom paddTop10 paddBtm5 paddRgt0">
                        {props.intl.formatMessage({ id: 'deviceProductDetails.subTitle', defaultMessage: 'Store Products' })}
                        </div>
                        {props.ProductTransactions.length > 0 ?
                            <div className="storeproductdatalist ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-xl8 paddLft20 removepaddLft20 removepadding">
                                <Iterator deviceId={props.Device.id}
                                    deviceGroupId={props.Device.id}
                                    transactionRecordId={props.transactionRecordId}
                                    disableSelection={props.DeviceType === DEVICE_TYPE.CONSOLE ? true : props.disableSelection}
                                    deviceType={props.DeviceType}
                                    Component={StoreProductCard}
                                    items={props.ProductTransactions} />
                            </div >
                            :
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-xl8 paddLft20 removepaddLft20">
                                <Label className="unAvailableProduct">
                                    {props.intl.formatMessage({ id: 'deviceProductDetails.noStoreProduct', defaultMessage: 'No store product available' })}                                
                                </Label>
                            </div>
                        }
                    </div>
                </AccordionBody>
            </Accordion>
        </div>
    );
}

DeviceProductDetails.propTypes = propTypes;
export default injectIntl(DeviceProductDetails);