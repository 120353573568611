import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SKUSearchResult } from '../SKUSearchResult'
import { ConsoleSearchResult } from '../ConsoleSearchResult';
import { DEVICE_TYPE } from '../../../common/constants';
import { injectIntl, intlShape } from 'react-intl';
import { TabIndexes } from '../../../common/constants/TabIndexes';
import * as deviceSelectionStepSelectors from '../DeviceSelectionStep/DeviceSelectionStep.selectors';
import './SearchDevice.scss';
import { bindActionCreators } from 'redux';
import { destroyManageDeviceStoreData } from '../DeviceProductSelectionStep';
import { setPageTitle } from '@ec-oem/ec.oem.oa3.ui.common/utility/pageTitle';
import { PageTitles , XBOXApplication} from '../../../common/constants';

const propTypes = {
    intl: intlShape.isRequired
}
class SearchDevice extends Component {
    componentWillMount() {
        this.props.destroyAction(true);
    }

    componentWillUnmount() {
        this.props.destroyAction(true);
    }
    componentDidMount() {
        setPageTitle(PageTitles.SEARCH_DEVICE, XBOXApplication);
    }
    
    render() {
        return (
            <div className="container search-Devices">
                <div className="page-header border-Bottom mrgBtm20">
                    <h1 tabIndex={TabIndexes.InOrder}>{this.props.intl.formatMessage({ id: 'searchDevice.title', defaultMessage: 'Search Device' })}</h1>
                </div>
                <div className="device-result">{
                    this.props.searchBy === DEVICE_TYPE.SKU ? <SKUSearchResult allowItemSelection={false} /> : <ConsoleSearchResult allowItemSelection={false} />}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    let initialSearchState = deviceSelectionStepSelectors.searchCriteriaSelector(state);

    let searchBy = DEVICE_TYPE.SKU;
    if (initialSearchState && initialSearchState.searchCriteria && initialSearchState.searchCriteria.SearchBy) {
        searchBy = initialSearchState.searchCriteria.SearchBy.value;
    }
    return {
        searchBy: searchBy,
    };
}
const mapDispatchToprops = (dispatch) => {
    return {
        destroyAction: bindActionCreators(destroyManageDeviceStoreData, dispatch),
    }
}

SearchDevice.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToprops)(SearchDevice));