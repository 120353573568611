'use strict';

import { StatusConstants, DEVICE_TYPE, DEVICE_IDENTIFIER_TYPE } from '../constants/XBOXConstants';
import { productMapper } from './Product'
import { getDateTime } from '../utility/dateMapper'

export class Device {
  constructor(deviceInfo, selected) {
    //For Selection
    this.id = deviceInfo.id ? deviceInfo.id : (deviceInfo.DeviceGroupId ? deviceInfo.DeviceGroupId : deviceInfo.SerialNumber);
    this.selected = selected;
    this.value = deviceInfo.DeviceType === DEVICE_TYPE.SKU ? deviceInfo.DeviceName : deviceInfo.SerialNumber;
    this.label = deviceInfo.DeviceType === DEVICE_TYPE.SKU ? 'SKU Part Number' : 'Serial Number';
    this.disabled = deviceInfo.disabled ? deviceInfo.disabled : false;
    // For Device Identification
    this.DeviceType = deviceInfo.DeviceType ? deviceInfo.DeviceType : '';

    //Master Fields    
    this.DeviceName = deviceInfo.DeviceName ? deviceInfo.DeviceName : '';
    this.FulfilmentId = deviceInfo.FulfilmentId ? deviceInfo.FulfilmentId : '';
    this.SerialNumber = deviceInfo.SerialNumber ? deviceInfo.SerialNumber : '';
    this.ProductFamily = deviceInfo.ProductFamily ? deviceInfo.ProductFamily : '';
    this.Rework = deviceInfo.Rework !== null ? deviceInfo.Rework : undefined;
    this.ReworkCount = deviceInfo.ReworkCount ? deviceInfo.ReworkCount : undefined;
    this.ConsoleVolume = deviceInfo.ConsoleVolume ? deviceInfo.ConsoleVolume : undefined;
    this.Attachments = deviceInfo.Attachments ? deviceInfo.Attachments : [];
    this.ProductsToBeAttached = deviceInfo.ProductsToBeAttached ? deviceInfo.ProductsToBeAttached : [];
    this.ProductsToBeDetached = deviceInfo.ProductsToBeDetached ? deviceInfo.ProductsToBeDetached : [];
    this.Status = deviceInfo.Status ? deviceInfo.Status : StatusConstants.STATUS_SUCCESS;
    this.ManufacturingDate = deviceInfo.ManufacturingDate ? deviceInfo.ManufacturingDate : '';
    this.SkuDescription = deviceInfo.SkuDescription ? deviceInfo.SkuDescription : ''
  }
}

export const skuToDevice = (skuResponse, isSelectionAllowed) => {
  let skuDevices = [];
  skuDevices = (skuResponse != null && skuResponse.length > 0) ?
    skuResponse.map((skuData, index) => {
      return new Device({
        DeviceType: DEVICE_TYPE.SKU,
        DeviceName: skuData.SkuNumber,
        DeviceGroupId: skuData.DeviceGroupId,
        ProductFamily: skuData.ProductFamily,
        Rework: skuData.Rework,
        ReworkCount: skuData.ReworkCount,
        ConsoleVolume: skuData.ConsoleCount,
        Status: skuData.Status,
        disabled: isSelectionAllowed ? (skuData.AttachmentsInProgress? true: false) : false,
        ManufacturingDate: getDateTime(skuData.ManufacturingDate),
        Attachments: productMapper(skuData.Attachments),
        SkuDescription: skuData.SkuDescription
      }, skuData.selected);
    }) : null
  return skuDevices;
}

export const consoleToDevice = (consoleInfo, isSelectionAllowed) => {
  let consoleDevice = [];
  if (consoleInfo) {
    consoleDevice.push(new Device({
      DeviceType: DEVICE_TYPE.CONSOLE,
      DeviceName: consoleInfo.SkuNumber,
      ProductFamily: consoleInfo.ProductFamily,
      SerialNumber: consoleInfo.SerialNumber,
      Status: consoleInfo.Status,
      disabled: isSelectionAllowed ? checkDisableDevice(consoleInfo.Attachments) : false,
      ManufacturingDate: getDateTime(consoleInfo.ManufacturingDate),
      Attachments: productMapper(consoleInfo.Attachments),
      SkuDescription: consoleInfo.SkuDescription
    }, consoleInfo.selected));
  }
  else
    return null;
  return consoleDevice;
}

export const transactionDetailsToDevice = (deviceDetail) => {
  if (deviceDetail) {
    return new Device({
      SerialNumber: deviceDetail.DeviceIdentifierType === DEVICE_IDENTIFIER_TYPE.SerialNumber ? deviceDetail.DeviceIdentifier : '',
      DeviceGroupId: deviceDetail.DeviceIdentifierType === DEVICE_IDENTIFIER_TYPE.DeviceGroup ? deviceDetail.DeviceIdentifier : '',
      DeviceType: deviceDetail.DeviceIdentifierType === DEVICE_IDENTIFIER_TYPE.SerialNumber ? DEVICE_TYPE.CONSOLE : DEVICE_TYPE.SKU,
      DeviceName: deviceDetail.SkuNumber,
      ProductFamily: deviceDetail.ProductFamily,
      Rework: deviceDetail.Rework,
      ConsoleVolume: deviceDetail.ConsoleCount,
      ManufacturingDate: getDateTime(deviceDetail.ManufacturingDate),
      Attachments: productMapper(deviceDetail.Attachments),
      FulfilmentId: deviceDetail.FulfilmentId,
      Status: deviceDetail.Status,
      SkuDescription: deviceDetail.SkuDescription
    }, false)
  }
  return null
}

const checkDisableDevice = (attachments) => {
  if (attachments) {
    let index = attachments.findIndex(attachment => attachment.Status === 0 || attachment.Status === 1)
    if (index > -1) {
      return true;
    }
  }

  return false;
}