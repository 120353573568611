'use strict';

import { DEVICE_STATUS } from "../constants";

export class DeviceTransactionStatus {
    constructor(deviceTransactionStatus) {
        this.SerialNumber = deviceTransactionStatus.SerialNumber ? deviceTransactionStatus.SerialNumber : '';
        this.Status = DEVICE_STATUS[deviceTransactionStatus.Status];
        this.FullfilmentId = deviceTransactionStatus.FullfilmentId;
    }
}

export const deviceTransactionStatusMapper = (deviceTransactionStatus) => {
    let deviceTransactionStatusInfo = []
    if (deviceTransactionStatus) {
        deviceTransactionStatus.map((deviceStatus) => {
            deviceTransactionStatusInfo.push(new DeviceTransactionStatus(deviceStatus))
        })
    }
    return deviceTransactionStatusInfo;
}