import { createSelector } from 'reselect';

export const manageDeviceWizardDataSelector = createSelector(
    state => state.app.get("manageDeviceWizardData"),
    manageDeviceWizardData => manageDeviceWizardData? manageDeviceWizardData.toJS(): null
);

export const manageDeviceStepSelector = (manageDeviceStepKey) => createSelector(
    state => state.app.get("manageDeviceWizardData"),
    manageDeviceWizardData => {
        return manageDeviceWizardData ? manageDeviceWizardData.toJS().find(item=> item.key===manageDeviceStepKey) : manageDeviceWizardData;            
    }   
);