export const PageTitles = {
    ATTACH_STORE_PRODUCTS: "Attach Store Products",
    DETACH_STORE_PRODUCTS: "Detach Store Products",
    MANAGE_DEVICE: "Manage Device Attachments",
    SEARCH_TRANSACTION: "Search Transaction",
    TRANSACTION_DETAILS: "Transaction Details",
    DEVICE_PRODUCT_TRANSACTION_DETAILS: "Device Attachments Transaction Details",
    SEARCH_DEVICE: "Search Device",
}

export const XBOXRoles = {
    XBOX_PRODUCER: "XBOXProducer",
    XBOX_CONSUMER: "XBOXConsumer"
}

export const XBOXThreshold = {
    PRODUCT: 1000,
    DEVICE: 100000
};

export const XBOXApplication = "XDAM";
export const RedirectPath = "/redirectURI";

export const StatusConstants = {
    STATUS_SUCCESS: 0,
    STATUS_DISABLE: 1
}

export const DurationConstants = [
    {
        value: 7,
        text: "Last 7 Days"
    },
    {
        value: 30,
        text: "Last 30 Days"
    },
    {
        value: 365,
        text: "Last 12 Months"
    },
    {
        value: 548,
        text: "Last 18 Months"
    },
    {
        value: 730,
        text: "Last 24 Months"
    },
    {
        value: 1095,
        text: "Last 36 Months"
    }
]

export const YesNoConstants = [
    {
        value: true,
        text: "Yes"
    },
    {
        value: false,
        text: "No"
    }
]

export const SEARCH_BY_OPTIONS = [
    'SKU',
    'CONSOLE'
]

export const BLANK_PLACEHOLDER = '-'

export const REWORK = "Rework"
export const SKUNUMBER = "SkuNumber"
export const DEVICE_NAME = "DeviceName"
export const MANUFACTURING_DATE = "ManufacturingDate"

export const SEARCH_TYPES = {
    CONSOLE_SEARCH: "CONSOLE_SEARCH",
    SKU_SEARCH: "SKU_SEARCH",
    ATTACH_PRODUCT_SEARCH: "ATTACH_PRODUCT_SEARCH",
    SEARCH_TRANSACTION: "SEARCH_TRANSACTION",
    SEARCH_DEVICE_PRODUCT_TRANSACTION: "SEARCH_DEVICE_PRODUCT_TRANSACTION",
    PRODUCT_SELECTION:"PRODUCT_SELECTION"
}
export const DEVICE_TYPE = {
    CONSOLE: "CONSOLE",
    SKU: "SKU",
}
export const TRANSACTION_STATUS = [
    {
        value: 0,
        key: 0,
        text: "New"
    },
    {
        value: 1,
        key: 1,
        text: "InProgress"
    },
    {
        value: 2,
        key: 2,
        text: "Completed"
    },
    {
        value: 3,
        key: 3,
        text: "Failed"
    }
]

export const DEVICE_IDENTIFIER_TYPE = {
    SerialNumber: 1,
    DeviceGroup: 2
}
export const DEVICE_STATUS = {
    0: 'New',
    1: 'InProgress',
    2: 'Success',
    3: 'Failed'
}
export const TRANSACTION_TYPE = {
    1: 'ATTACH',
    2: 'DETACH'
}
export const PROCESS_TYPE = {
    1: 'Attachment',
    2: 'Detachment',
    3: 'Reattachment'
}
