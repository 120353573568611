import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.common/utility';
import * as StoreProductSelectionStepAction from './ProductSelection.actions';
import ApplicationConfig from '../../../common/utility/applicationConfig'
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions';
import { Product, SearchResultData } from '../../../common/models'
import { showResponseMessage } from '../../../common/actions'

const getStoreProductData = (storeProductitems) => {
    return storeProductitems.map((storeProduct) => {

        return new Product({
            ProductId: storeProduct.ProductId,
            ProductFamily: storeProduct.ProductFamily,
            Title: storeProduct.Title,
            Icon: storeProduct.Icon,
            id: storeProduct.ProductId,
            selected: false,
            value: storeProduct.ProductId
        }, false);
    })
}
const getSearchRequest = (searchFilter, searchText) => {
    let queryParams = "";  
    queryParams = queryParams + (searchText ? "productId=" + searchText : "");
    queryParams = queryParams + (searchText ? "&Title=" + searchText : "");
    queryParams = queryParams + (searchFilter.page ? "&PageNumber=" + searchFilter.page : "");
    queryParams = queryParams + (searchFilter.pageSize ? "&PageSize=" + searchFilter.pageSize : "");
    return queryParams;
}

export function* storeProductSelectionSaga() {
    while (true) {
        try {
            const { searchStoreProductFilter, searchText } = yield take(StoreProductSelectionStepAction.GET_PANEL_STORE_PRODUCT);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/products?` + getSearchRequest(searchStoreProductFilter, searchText), method: 'GET' },IsPanelTask: true, SagaHandleResponse: { HandleResponse: false } });
            if (response.status === 200) {
                const data = yield apply(response, response.json);
                if (data.TotalCount > 0) {
                    let storeProducts = new SearchResultData({
                        Records: getStoreProductData(data.Records),
                        TotalCount: data.TotalCount,
                        CurrentPage: data.CurrentPage,
                        PageSize: data.PageSize,
                        Token: null
                    });
                    yield put(StoreProductSelectionStepAction.setPanelStoreProduct(storeProducts));
                }
                else {
                    let storeProductItem = new SearchResultData({
                        Records: [],
                        TotalCount: 0,
                        CurrentPage: 0,
                        PageSize: 0,
                        Token: null
                    });
                    yield put(StoreProductSelectionStepAction.setPanelStoreProduct(storeProductItem));
                }
            } else {
                yield put(showResponseMessage(response));
                yield put(StoreProductSelectionStepAction.setPanelStoreProduct(null));
            }
        } catch (error) {
            yield put(throwException("searchConsoleSaga error: " + error));
            yield put(StoreProductSelectionStepAction.setPanelStoreProduct(null));
            let errorObj = {
                statusText: "Something went wrong. Please contact OEM helpdesk for more assistance."
            }
            yield put(showResponseMessage(errorObj)); 
        }
    }
}

