import { put, take } from 'redux-saga/effects';
import { GET_MANAGEDEVICE_WIZARD_DATA, setManageDeviceWizardData } from './ManageDeviceAttachments.actions';
import manageDeviceAttachmentsWizardData from './ManageDeviceAttachmentsWizardConfig.json';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions';
import {WizardStepInfo} from '../../../common/models/WizardStepInfo';

export function* getManageDeviceAttachmentsWizardDataSaga() {
    while (true) {
        try {            
            yield take(GET_MANAGEDEVICE_WIZARD_DATA);     
            let wizardData = [];
            manageDeviceAttachmentsWizardData.forEach(element => {
                let wizardInfo = new WizardStepInfo({
                    key: element.key,
                    text: element.text,
                    order: element.order,
                    status: element.status,
                    inputData: null,
                    isValid: element.isStateValid
                });                
                wizardData.push(wizardInfo);
            });        
            yield put(setManageDeviceWizardData(wizardData));
        } catch (error) {
            yield put(throwException("getManageDeviceAttachmentsWizardDataSaga error: " + error));
        }
    }
}