import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility";

export const GET_TRANSACTION = "COMMON/SEARCH_TRANSACTION/GET_TRANSACTION";
export const getTransaction = makeActionCreator(GET_TRANSACTION, true, "searchFilter", "searchTransactionCriteria");

export const SET_TRANSACTION = "COMMON/SEARCH_TRANSACTION/SET_TRANSACTION";
export const setTransaction = makeActionCreator(SET_TRANSACTION, true, "searchTransactionResult");

export const SET_SEARCH_CRITERIA = "COMMON/SEARCH_TRANSACTION/SET_SEARCH_CRITERIA";
export const setSearchCriteria = makeActionCreator(SET_SEARCH_CRITERIA, true, "searchTransactionCriteria");


export const RESET_TRANSACTION_DATA = "COMMON/SEARCH_TRANSACTION/RESET_TRANSACTION_DATA";
export const resetTransactionData = makeActionCreator(RESET_TRANSACTION_DATA, true);


