import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import * as consoleSelectionStepSelectors from '../../components/DeviceSelectionStep/DeviceSelectionStep.selectors';
import { getFormattedDate } from '../../../common/utility/dateMapper';
import { SKUCard } from '../../../common/components';
import { DeviceFilter } from '../DeviceFilter';
import { SKUFilterMapper, SKUResultFilterMapper, SKUSortMapper, SKUResultSortMapper } from './SKUMapper';
import { InMemorySearchResult, SearchResultMappers, SearchDefaultSelectionTypes } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { SEARCH_TYPES } from '../../../common/constants';

const skuMappers = {
    [SearchResultMappers.FILTER_MAPPER]: SKUFilterMapper,
    [SearchResultMappers.RESULT_FILTER_MAPPER]: SKUResultFilterMapper,
    [SearchResultMappers.SORT_MAPPER]: SKUSortMapper,
    [SearchResultMappers.RESULT_SORT_MAPPER]: SKUResultSortMapper
}

const propTypes = {
    intl: intlShape.isRequired,
    allowItemSelection: PropTypes.bool
}

const getDefaultSelections = () => {
    return {
        [SearchDefaultSelectionTypes.PAGING_OPTION]: {
            page: 1,
            pageSize: 12
        },
        [SearchDefaultSelectionTypes.SELECTED_FILTERS]: null,
        [SearchDefaultSelectionTypes.SELECTED_ITEMS]: null,
        [SearchDefaultSelectionTypes.SORT_OPTION]: null
    }
}

class SKUSearchResult extends React.Component {
    getSearchInputs = (isAdvanceSearch, searchCriteria) => {
        let searchInputs = [];

        if (searchCriteria && searchCriteria.SearchBy && searchCriteria.SearchBy.value) {
            let input = {
                label: this.props.intl.formatMessage({ id: 'selectConsole.deviceType', defaultMessage: 'Device Type' })
            }
            input.value = searchCriteria.SearchBy.text;
            searchInputs.push(input);

            if (searchCriteria.SKUNumber && searchCriteria.SKUNumber.value) {
                input = {
                    label: this.props.intl.formatMessage({ id: 'selectConsole.skuNumber', defaultMessage: 'SKU Part Number' })
                }
                input.value = searchCriteria.SKUNumber.value;
                searchInputs.push(input);
            }
        }

        if (isAdvanceSearch) {
            if (searchCriteria.StoreProducts && searchCriteria.StoreProducts.value) {
                let input = {
                    label: this.props.intl.formatMessage({ id: 'selectConsole.storeProducts', defaultMessage: 'Digital Attach Product' })
                }
                input.value = searchCriteria.StoreProducts.value;
                searchInputs.push(input);
            }
            if (searchCriteria.BuildDate && searchCriteria.BuildDate.value) {
                let input = {
                    label: this.props.intl.formatMessage({ id: 'selectConsole.buildDate', defaultMessage: 'MFG Date' })
                }
                input.value = getFormattedDate(searchCriteria.BuildDate.value);
                searchInputs.push(input);
            }
            if (searchCriteria.Description && searchCriteria.Description.value) {
                let input = {
                    label: this.props.intl.formatMessage({ id: 'selectConsole.description', defaultMessage: 'Description' })
                }
                input.value = searchCriteria.Description.value;
                searchInputs.push(input);
            }
        }
        return searchInputs;
    }

    render() {
        return (
            <React.Fragment>
                <div className="ms-Grid select-console removepadding">
                    <DeviceFilter allowItemSelection={this.props.allowItemSelection !== undefined ? this.props.allowItemSelection : false}></DeviceFilter>
                </div>
                <InMemorySearchResult searchName={SEARCH_TYPES.SKU_SEARCH}
                    searchCriteria={this.getSearchInputs(this.props.isAdvanceSearch, this.props.searchCriteria)}
                    defaultSelections={getDefaultSelections()}
                    ResultIteratorComponent={SKUCard}
                    allowItemSelection={this.props.allowItemSelection !== undefined ? this.props.allowItemSelection : true}
                    mappers={skuMappers}></InMemorySearchResult>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    let initialSearchState = consoleSelectionStepSelectors.searchCriteriaSelector(state);
    let isAdvanceSearch = false;
    let searchCriteria = null;

    if (initialSearchState) {
        isAdvanceSearch = initialSearchState.isAdvanceSearch;
        searchCriteria = initialSearchState.searchCriteria;
    }

    return {
        isAdvanceSearch: isAdvanceSearch,
        searchCriteria: searchCriteria
    };
}

SKUSearchResult.propTypes = propTypes;

export default injectIntl(connect(mapStateToProps, null)(SKUSearchResult));