import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { fromJS } from "immutable";
import * as DeviceProductSelectionStepActions from './DeviceProductSelectionStep.actions';

export const manageDeviceSelection = createReducer(null, {
    [DeviceProductSelectionStepActions.SET_MANAGE_DEVICE_SELECTION](state, { manageDeviceSelection }) {
        return fromJS(manageDeviceSelection)
    }
})

export const manageDeviceResponse = createReducer(null, {
    [DeviceProductSelectionStepActions.SET_MANAGEDEVICE_RESPONSE](state, { manageDeviceResponse }) {
        return (manageDeviceResponse)
    }
})
