import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility";
 
export const GET_MANAGEDEVICE_WIZARD_DATA = "components/ManageDevice/GET_MANAGEDEVICE_WIZARD_DATA";
export const getManageDeviceWizardData = makeActionCreator(GET_MANAGEDEVICE_WIZARD_DATA, false);

export const SET_MANAGEDEVICE_WIZARD_DATA = "components/ManageDevice/SET_MANAGEDEVICE_WIZARD_DATA";
export const setManageDeviceWizardData = makeActionCreator(SET_MANAGEDEVICE_WIZARD_DATA, false, "wizardData");

export const SET_MANAGEDEVICE_WIZARD_STEP_DATA = "components/ManageDevice/SET_MANAGEDEVICE_WIZARD_STEP_DATA";
export const setManageDeviceWizardStepData = makeActionCreator(SET_MANAGEDEVICE_WIZARD_STEP_DATA, false, "stepData");

export const SET_MANAGEDEVICE_WIZARD_CURRENT_STEP = "components/ManageDevice/SET_MANAGEDEVICE_WIZARD_CURRENT_STEP";
export const setManageDeviceWizardCurrentStep = makeActionCreator(SET_MANAGEDEVICE_WIZARD_CURRENT_STEP, false, "stepData");

export const MANAGEDEVICE_WIZARD_STEP_NEXT = "components/ManageDevice/MANAGEDEVICE_WIZARD_STEP_NEXT";
export const manageDeviceWizardStepNext = makeActionCreator(MANAGEDEVICE_WIZARD_STEP_NEXT, true, "stepData");

export const MANAGEDEVICE_WIZARD_STEP_PREVIOUS = "components/ManageDevice/MANAGEDEVICE_WIZARD_STEP_PREVIOUS";
export const manageDeviceWizardStepPrevious = makeActionCreator(MANAGEDEVICE_WIZARD_STEP_PREVIOUS, true, "stepData");

export const MANAGEDEVICE_WIZARD_SET_STEP = "components/ManageDevice/MANAGEDEVICE_WIZARD_SET_STEP";
export const manageDeviceWizardSetStep = makeActionCreator(MANAGEDEVICE_WIZARD_SET_STEP, true, "stepData");