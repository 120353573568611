import * as React from 'react';
import { Label } from 'office-ui-fabric-react';
import { Card, CardBody, Iterator } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { injectIntl } from 'react-intl';
import './StoreProductCard.scss';
import PropTypes from 'prop-types';
import { Product } from '../../models';
import { BLANK_PLACEHOLDER } from '../../constants';
import { TabIndexes } from '@ec-oem/ec.oem.oa3.ui.common/constants';
import LinesEllipsis from 'react-lines-ellipsis';
import history from '../../utility/history';
import { ProductStatus } from '../ProductStatus';
import { TRANSACTION_TYPE } from '../../constants'

const propTypes = {
    disableSelection: PropTypes.bool,
    deviceId: PropTypes.string,
    Title: PropTypes.string,
    ProductId: PropTypes.string,
    deviceGroupId: PropTypes.string,
    transactionRecordId: PropTypes.string,
    TransactionStatusSummary: PropTypes.array,
    deviceType: PropTypes.string,
    OperationType: PropTypes.string,
    onItemClick: PropTypes.func,
    onItemChange: PropTypes.func,
    selected: PropTypes.bool
}

class StoreProductCard extends React.Component {
    handleCardClick = () => {
        if (this.props.TransactionStatusSummary) {
            if (this.props.transactionRecordId !== undefined && this.props.deviceGroupId !== undefined)
                history.push(`/transactionDetails/${this.props.transactionRecordId}/${this.props.deviceGroupId}/${this.props.ProductId}`);
        }
        if (this.props.disableSelection)
            return;
        else {
            let selectedItem = new Product({
                id: this.props.id,
                ProductId: this.props.ProductId,
                ProductFamily: this.props.ProductFamily,
                SKU: this.props.SKU,
                AvailabiltyId: this.props.AvailabiltyId,
                RedemptionStartDate: this.props.RedemptionStartDate,
                RedemptionEndDate: this.props.RedemptionEndDate,
                Title: this.props.Title,
                Icon: this.props.Icon,
                label: this.props.intl.formatMessage({ id: 'app.pkId', defaultMessage: "PK ID" })
            }, this.props.selected);
            this.props.onItemClick && this.props.onItemClick(selectedItem);
        }
    }

    getCardClassName = () => {
        let cardClassName = 'ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-xl4 store-product-card removepadding';
        if (this.props.disableSelection) {
            cardClassName = cardClassName + (this.props.OperationType === TRANSACTION_TYPE[2] ? " detach-selected-product " : " attach-new-product ");
            if (this.props.TransactionStatusSummary) {
                cardClassName = cardClassName + " pointer-cursor";
            }
        }
        else {
            cardClassName = cardClassName + " pointer-cursor";
            if (this.props.selected) {
                cardClassName = cardClassName + (this.props.OperationType === TRANSACTION_TYPE[2] ? " detach-selected-product " : "  attach-selected-product ");
            }
        }
        return cardClassName;
    }

    getAriaLabel = () => {
        let ariaLabel = this.props.intl.formatMessage({ id: 'selectConsole.selectStoreCard', defaultMessage: 'Click tab for next card.' });
        if (!this.props.disableSelection && this.props.selected) {
            ariaLabel += this.props.intl.formatMessage({ id: 'selectConsole.selectedCard', defaultMessage: 'This card is Selected' });
        }
        else {
            ariaLabel += this.props.intl.formatMessage({ id: 'selectConsole.notSelectedCard', defaultMessage: 'This card is not Selected' });
        }
        return ariaLabel;
    }

    getPointerClassName = () => {
        return this.props.disableSelection ? '' : 'pointer-cursor';
    }

    removeCardClick = () => {
        if (this.props.disableSelection) {
            let selectedItem = new Product({
                id: this.props.id,
                ProductId: this.props.ProductId,
                ProductFamily: this.props.ProductFamily,
                SKU: this.props.SKU,
                AvailabiltyId: this.props.AvailabiltyId,
                RedemptionStartDate: this.props.RedemptionStartDate,
                RedemptionEndDate: this.props.RedemptionEndDate,
                Title: this.props.Title,
                Icon: this.props.Icon,
                label: this.props.intl.formatMessage({ id: 'app.pkId', defaultMessage: "PK ID" })
            }, this.props.selected);
            this.props.onItemChange && this.props.onItemChange(selectedItem);
        }
    }

    render() {
        let pointerClassName = this.getPointerClassName();
        const { index, deviceId, ProductId } = this.props;
        return (
            <div className={this.getCardClassName()}
                aria-label={this.getAriaLabel()}
                onClick={() => this.handleCardClick()}
                onKeyPress={() => this.handleCardClick()}
                tabIndex={this.props.disableSelection ? TabIndexes.OutOfOrder : TabIndexes.InOrder}>
                <Card>
                    <CardBody>
                        <div className={pointerClassName}>
                            <div className="display-inline-flex">
                                <i className={"ms-Icon ms-Icon--BoxCheckmarkSolid  card-selected-icon removepadding " + pointerClassName} aria-hidden="true"></i>
                                <div className={"store-product-details " + pointerClassName} >
                                    <Label name="PKIdValue" id={`PKIdValue${index}${deviceId}${ProductId}`} className="label-value ms-sm12 removepadding">{this.props.ProductId ? this.props.ProductId : BLANK_PLACEHOLDER}</Label>
                                    <LinesEllipsis
                                        name="descriptionValue" id={`descriptionValue${index}${deviceId}${ProductId}`} className="description-value  ms-sm12 ms-md12  ms-lg12  removepadding"
                                        text={this.props.Title ? this.props.Title : BLANK_PLACEHOLDER} maxLine="3" ellipsis='...'
                                        title={this.props.Title ? this.props.Title : BLANK_PLACEHOLDER} />
                                </div>
                                <div>{this.props.onItemChange &&
                                    <i role='button' aria-label={'Click enter to Remove new attach product'} onKeyDown={(e) => { if (e.keyCode === 13) this.removeCardClick() }} onClick={() => this.removeCardClick()} tabIndex="0" title="Remove" className={"ms-Icon ms-Icon--Clear  card-selected-remove-icon removepadding " + pointerClassName}></i>
                                }</div>
                            </div>
                            {this.props.TransactionStatusSummary &&
                                <div tabIndex={(this.props.transactionRecordId != undefined && this.props.deviceGroupId != undefined) ? TabIndexes.InOrder : TabIndexes.OutOfOrder} className={"product-console-status-show " + pointerClassName}>
                                    <Iterator deviceType={this.props.deviceType} Component={ProductStatus} itemLength={this.props.TransactionStatusSummary.length}
                                        disableSelection={this.props.disableSelection}
                                        items={this.props.TransactionStatusSummary} />
                                </div>
                            }
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

StoreProductCard.propTypes = propTypes
export default injectIntl(StoreProductCard);
