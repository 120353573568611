import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { fromJS } from "immutable";
import * as SearchTransaction from './SearchTransaction.actions';

export const searchTransactionResult = createReducer(null, {
    [SearchTransaction.SET_TRANSACTION](state, { searchTransactionResult }) {
        return searchTransactionResult
    }
})
export const searchTransactionCriteria = createReducer(null,{ 
    [SearchTransaction.SET_SEARCH_CRITERIA](state, { searchTransactionCriteria }) {
      return fromJS(searchTransactionCriteria)
    }
})