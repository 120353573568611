import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { fromJS } from "immutable";
import * as deviceFilterActions from './DeviceSelectionStep.actions';

export const searchCriteria = createReducer(null,{ 
    [deviceFilterActions.SET_SEARCH_CRITERIA](state, { searchCriteria }) {
      return fromJS(searchCriteria)
    }
})

export const selectedDevice = createReducer(null,{ 
    [deviceFilterActions.SET_SELECTED_CONSOLE_SKU](state, { selectedDevice }) {
      return fromJS(selectedDevice)
    }
})