import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility";

export const GET_DEVICE_PRODUCT_TRANSACTION_DETAILS = "components/DeviceProductTransactionDetails/GET_DEVICE_PRODUCT_TRANSACTION_DETAILS";
export const getDeviceProductTransactionDetails = makeActionCreator(GET_DEVICE_PRODUCT_TRANSACTION_DETAILS, false, "transactionId", "deviceGroupId", "productId");

export const SET_DEVICE_PRODUCT_TRANSACTION_DETAILS = "components/DeviceProductTransactionDetails/SET_DEVICE_PRODUCT_TRANSACTION_DETAILS";
export const setDeviceProductTransactionDetails = makeActionCreator(SET_DEVICE_PRODUCT_TRANSACTION_DETAILS, false, "deviceProductTransactionDetails");


export const FILTER_DEVICE_PRODUCT_TRANSACTIONS = "components/DeviceProductTransactionDetails/FILTER_DEVICE_PRODUCT_TRANSACTIONS";
export const filterDeviceProductTransactions = makeActionCreator(FILTER_DEVICE_PRODUCT_TRANSACTIONS, false, "searchText");