'use strict'

export class SearchResultData {
    constructor(responseData) {
        this.Records = responseData.Records;
        this.TotalCount = responseData.TotalCount;
        this.CurrentPage = responseData.CurrentPage;
        this.PageSize = responseData.PageSize;
        this.Token = responseData.Token;
    }
}