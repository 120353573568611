'use strict';
import { productMapper } from './Product';
import { transactionDetailsToDevice } from './Device';
import { deviceTransactionStatusMapper } from './DeviceTransactionStatus';
import { transactionStatusSummaryMapper } from './TransactionStatusSummary';

export class DeviceProductTransactionDetails {
    constructor(deviceProductStatus) {
        this.Device = deviceProductStatus.Device ? deviceProductStatus.Device : null;
        this.Product = deviceProductStatus.Product ? deviceProductStatus.Product : [];
        this.TransactionStatusSummary = deviceProductStatus.TransactionStatusSummary ? deviceProductStatus.TransactionStatusSummary : null;
        this.DeviceTransactionStatus = deviceProductStatus.DeviceTransactionStatus ? deviceProductStatus.DeviceTransactionStatus : [];
    }
}

export const deviceProductStatusInfoMapper = (deviceProductStatus) => {
    if (deviceProductStatus) {
        return new DeviceProductTransactionDetails({
            Device: transactionDetailsToDevice(deviceProductStatus.Device),
            Product: deviceProductStatus.Product !== null ? productMapper([deviceProductStatus.Product]) : null,
            TransactionStatusSummary: transactionStatusSummaryMapper(deviceProductStatus.TransactionStatusSummary),
            DeviceTransactionStatus: deviceTransactionStatusMapper(deviceProductStatus.DeviceTransactionStatus)
        })
    }
}