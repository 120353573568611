import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.common/utility';
import * as DeviceSelectionStepActions from './DeviceSelectionStep.actions';
import { skuToDevice, consoleToDevice } from '../../../common/models';
import ApplicationConfig from '../../../common/utility/applicationConfig';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions';

import { updateSearchResults, destroySearch } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { SEARCH_TYPES } from '../../../common/constants';
import { showResponseMessage } from '../../../common/actions'

const getSearchRequest = (searchCriteria) => {
    let queryParams = 'SkuSearchText=' + searchCriteria.SKUNumber;
    queryParams += (searchCriteria.StoreProducts ? "&StoreProductSearchText=" + searchCriteria.StoreProducts : "");
    queryParams += (searchCriteria.Description ? "&SkuDescriptionSearchText=" + searchCriteria.Description : "");
    queryParams += (searchCriteria.BuildDate ? "&ManufacturingDate=" + searchCriteria.BuildDate : "");
    return queryParams;
}

export function* searchByConsoleSaga() {
    while (true) {
        try {
            let consoleData = null;
            const { searchCriteria, isSelectionAllowed } = yield take(DeviceSelectionStepActions.GET_SEARCH_BY_CONSOLE_RESULT);
            const config = yield ApplicationConfig.config;

            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/xbox/` + searchCriteria.SerialNumber, method: 'GET' }, SagaHandleResponse: { HandleResponse: false } });
            if (response.status === 200) {
                const console = yield apply(response, response.json);
                if (console) {
                    consoleData = consoleToDevice(console, isSelectionAllowed);
                }                
            }
            else if (response.status !== 404)
            {
                yield put(showResponseMessage(response));               
            }
            yield put(updateSearchResults(SEARCH_TYPES.CONSOLE_SEARCH, consoleData, true));
        } catch (error) {
            yield put(throwException("searchByConsoleSaga error: " + error));
            let errorObj = {
                statusText: "Something went wrong. Please contact OEM helpdesk for more assistance."
            }
            yield put(showResponseMessage(errorObj)); 
        }
    }
}

export function* searchBySKUSaga() {
    while (true) {
        try {
            let skuData = null;
            const { searchCriteria, isSelectionAllowed } = yield take(DeviceSelectionStepActions.GET_SEARCH_BY_SKU_RESULT);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/xbox/summary?` + getSearchRequest(searchCriteria), method: 'GET' }, SagaHandleResponse: { HandleResponse: false } });
            if (response.status === 200) {
                const sku = yield apply(response, response.json);
                if (sku && sku.length > 0) {
                    skuData = skuToDevice(sku, isSelectionAllowed);
                }
                yield put(updateSearchResults(SEARCH_TYPES.SKU_SEARCH, skuData, true));
            }
            else{
                yield put(showResponseMessage(response));
                yield put(updateSearchResults(SEARCH_TYPES.SKU_SEARCH, skuData, true));
            }

        } catch (error) {
            yield put(throwException("searchBySKUSaga error: " + error));
            let errorObj = {
                statusText: "Something went wrong. Please contact OEM helpdesk for more assistance."
            }
            yield put(showResponseMessage(errorObj)); 
        }
    }
}

export function* destroyStoreDataSaga() {
    while (true) {
        try {
            yield take(DeviceSelectionStepActions.DESTROY_STORE_DATA);

            //Search Result Data
            yield put(destroySearch(SEARCH_TYPES.SKU_SEARCH));
            yield put(destroySearch(SEARCH_TYPES.CONSOLE_SEARCH));
            yield put(destroySearch(SEARCH_TYPES.ATTACH_PRODUCT_SEARCH));

            //Console Data
            yield put(DeviceSelectionStepActions.setSearchCriteria(null));
            yield put(DeviceSelectionStepActions.setSelectedDevice(null));

            //Store Product Data
            //yield put(AttachStoreProductActions.setSearchText(null));
            //yield put(AttachStoreProductActions.setStoreProduct(null));
            //yield put(AttachStoreProductActions.setSelectedStoreProduct(null));
            //yield put(DetachStoreProductActions.setSelectedBundleResult(null));
        }
        catch (error) {
            yield put(throwException("destroyStoreDataSaga error: " + error));
        }
    }
}