import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DefaultButton } from 'office-ui-fabric-react';
import { injectIntl, intlShape } from 'react-intl';
import * as consoleSelectionStepActions from './DeviceSelectionStep.actions';
import * as consoleSelectionStepSelectors from './DeviceSelectionStep.selectors';
import { WizardStep, WizardStepBody, WizardStepFooter, WizardStepHeader } from '@ec-oem/ec.oem.oa3.ui.core/components';
import './DeviceSelectionStep.scss';
import { SKUSearchResult, ConsoleSearchResult } from '../../components'
import { SEARCH_TYPES, IconConstants, DEVICE_TYPE, XBOXThreshold } from '../../../common/constants';
import { SearchResultConstants, searchResultInfoItemSelector } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { TabIndexes } from '../../../common/constants/TabIndexes';
import { showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { AlertsTypes, AlertsFeature } from "@ec-oem/ec.oem.oa3.ui.core/constants/CoreConstants";
import { isNullOrUndefined } from 'util';

const propTypes = {
    intl: intlShape.isRequired,
    initialState: PropTypes.object    
}

class DeviceSelctionStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wizardState: this.props.initialState
        }
    }

    submitBtnEnable = () => {
        return !(this.props.selectedItems && this.props.selectedItems.length > 0);
    }
    tabIndex = () => {
        if (this.props.selectedItems && this.props.selectedItems.length > 0) {
            var tabindex = TabIndexes.InOrder;
        }
        else
            tabindex = TabIndexes.OutOfOrder;
        return tabindex;
    }

    onNextClick = () => {
        let wizardState = this.state.wizardState;
        let selectedItems = this.props.selectedItems;
        wizardState.isStateValid = true;
        this.props.actions.setSelectedDevice(selectedItems);
        this.props.onSubmitClick(wizardState);
    }

    render() {
        return (
            <div className="attach-header-container removepadding">
                <WizardStep>
                    <WizardStepHeader title={this.props.intl.formatMessage({ id: 'selectConsole.headerTitle', defaultMessage: 'Select Device' })} />
                    <WizardStepBody>
                        <div className="device-result">
                            {
                                this.props.searchBy === DEVICE_TYPE.SKU ? <SKUSearchResult allowItemSelection={true}/> : <ConsoleSearchResult allowItemSelection={true}/>
                            }
                        </div>
                    </WizardStepBody>
                    <WizardStepFooter>
                        <div>
                            <DefaultButton
                                id="nextButton"
                                className="iconRight floatRight paddRgt5"
                                primary={true}
                                data-automation-id="test"
                                disabled={this.submitBtnEnable()}
                                text={this.props.intl.formatMessage({ id: 'app.next', defaultMessage: 'Next' })}
                                iconProps={{ iconName: IconConstants.ChevronRightSmall }}
                                onClick={() => { this.onNextClick() }}
                                allowDisabledFocus={false}
                                tabIndex={this.tabIndex()} />
                        </div>
                    </WizardStepFooter>
                </WizardStep>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let initialSearchState = consoleSelectionStepSelectors.searchCriteriaSelector(state);

    let searchBy = DEVICE_TYPE.SKU;
    if (initialSearchState && initialSearchState.searchCriteria && initialSearchState.searchCriteria.SearchBy) {
        searchBy = initialSearchState.searchCriteria.SearchBy.value;
    }

    let selectedItems = null;
    if (searchBy === DEVICE_TYPE.SKU) {
        selectedItems = searchResultInfoItemSelector(state, SEARCH_TYPES.SKU_SEARCH, SearchResultConstants.SELECTED_ITEMS);
    }
    else {
        selectedItems = searchResultInfoItemSelector(state, SEARCH_TYPES.CONSOLE_SEARCH, SearchResultConstants.SELECTED_ITEMS);
    }

    return {
        searchBy: searchBy,
        selectedItems: selectedItems
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(consoleSelectionStepActions, dispatch),
        showAlert: bindActionCreators(showAlert, dispatch)      
    }
}

DeviceSelctionStep.propTypes = propTypes;

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeviceSelctionStep));