import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { fromJS } from "immutable";
import * as StoreProductSelectionStep from './ProductSelection.actions';

export const panelStoreProduct = createReducer(null, {
    [StoreProductSelectionStep.SET_PANEL_STORE_PRODUCT](state, { panelStoreProduct }) {
        return fromJS(panelStoreProduct)
    }
})
export const panelSelectedStoreProduct = createReducer(null, {
    [StoreProductSelectionStep.SET_PANEL_SELECTED_STORE_PRODUCT](state, { panelSelectedStoreProduct }) {
        return fromJS(panelSelectedStoreProduct)
    }
})
