import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DefaultButton, Link } from 'office-ui-fabric-react';
import { injectIntl, intlShape } from 'react-intl';
import * as manageDeviceActions from '../ManageDeviceAttachments/ManageDeviceAttachments.actions';
import { WizardStep, WizardStepBody, WizardStepFooter, WizardStepHeader, Iterator } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { DeviceProductMapping } from '../DeviceProductMapping';
import { selectedBundleSelector } from './DeviceProductSelectionStep.selectors'
import * as deviceProductSelectionStepActions from './DeviceProductSelectionStep.actions'
import { manageDeviceResponseSelector } from '../DeviceProductSelectionStep/DeviceProductSelectionStep.selectors';
import { selectedDeviceSelector, searchCriteriaSelector } from '../DeviceSelectionStep/DeviceSelectionStep.selectors'
import "./DeviceProductSelectionStep.scss"
import { IconConstants, TRANSACTION_TYPE, XBOXThreshold } from '../../../common/constants';
import { ManageDeviceConstants } from '../../constants';
import { DeviceProductSelection } from '../DeviceProductSelection';
import { DeviceTransactionStatus } from '../DeviceTransactionStatus';
import { ConfirmationPopup } from '../../../common/components'
import history from '../../../common/utility/history';
import { showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { AlertsTypes, AlertsFeature } from "@ec-oem/ec.oem.oa3.ui.core/constants/CoreConstants";
import { isNullOrUndefined } from 'util';

const propTypes = {
    initialState: PropTypes.object,
    intl: intlShape.isRequired
}

class DeviceProductSelectionStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wizardState: this.props.initialState,
            devices: this.props.devices,
            selectedBundle: this.props.selectedBundle ? this.props.selectedBundle : [],
            showDeviceProductSelectionPanel: false,
            expandCollapseStatusList: [],
            isExpand: true,
            showPopup: false,
            popupType:''
        }
    }

    componentWillMount() {
        if (this.props.devices) {
            let devices = this.updateDevicesWithSelectedBundle(this.props.devices, this.props.selectedBundle);
            this.setState({
                devices: devices
            })
        }
    }

    componentWillReceiveProps() {
        if (this.props.manageDeviceResponse) {
            let currentState = this.state.wizardState;
            currentState.status = ManageDeviceConstants.COMPLETED;
            this.props.manageDeviceActions.setManageDeviceWizardStepData(currentState);
        }
    }

    componentWillUnmount() {
        //this.props.actions.destroyManageDeviceStoreData(true);
    }

    updateDevicesWithSelectedBundle = (devices, selectedBundle) => {
        devices.forEach(device => {
            let itemIndex = selectedBundle && selectedBundle.findIndex(bundle => bundle.id === device.id);
            if (selectedBundle && itemIndex > -1) {
                device.ProductsToBeDetached = selectedBundle[itemIndex].ProductsToBeDetached;
                device.ProductsToBeAttached = selectedBundle[itemIndex].ProductsToBeAttached;

                device.Attachments.forEach(prod => {
                    let selectedProduct = selectedBundle[itemIndex].ProductsToBeDetached.find(item => item.ProductId === prod.ProductId);
                    prod.selected = selectedProduct ? true : false;
                    prod.OperationType = TRANSACTION_TYPE[2];
                })
            }
            else {
                device.ProductsToBeDetached = [];
                device.ProductsToBeAttached = [];
                device.Attachments.forEach(prod => {
                    prod.selected = false;
                    prod.OperationType = TRANSACTION_TYPE[2];
                })
            }
            device.isExpand = true;
        })
        return devices;
    }

    handleChange = (id, product) => {
        let devices = this.state.devices;
        let index = devices.findIndex(item => item.id === id);
        if (index > -1) {
            let device = devices[index];
            let prodIndex = device.Attachments.findIndex(item => item.ProductId === product.ProductId)
            if (prodIndex > -1) {
                device.Attachments[prodIndex] = product;
            }
            device.ProductsToBeDetached = device.Attachments.filter(prod => prod.selected === true);
            devices[index] = device;
        }
        this.setState({
            devices: devices
        })
    }

    onAddProductClick = () => {
        this.setState({
            showDeviceProductSelectionPanel: true
        })
    }

    hidePanel = () => {
        this.setState({
            showDeviceProductSelectionPanel: false
        })
    }

    changeDeviceProductSelection = (devicesToBeUpdated, products) => {
        let devices = this.state.devices;
        devices && devices.forEach(device => {
            let deviceIndex = devicesToBeUpdated.findIndex(item => item.id === device.id);
            if (deviceIndex > -1) {
                products && products.forEach(product => {
                    let prodIndex = device.Attachments.findIndex(item => item.ProductId === product.ProductId);
                    if (prodIndex > -1) {
                        if (device.Attachments[prodIndex].selected) {
                            device.Attachments[prodIndex].selected = false;
                            device.ProductsToBeDetached = device.ProductsToBeDetached.filter(item => item.ProductId !== product.ProductId);
                        }
                    }
                    else {
                        if (!device.ProductsToBeAttached.some(item => item.ProductId === product.ProductId)) {
                            product.OperationType = TRANSACTION_TYPE[1];
                            device.ProductsToBeAttached.push(product);
                        }
                    }
                });
            }
        });
        this.setState({
            devices: devices,
            showDeviceProductSelectionPanel: false
        })
    }

    onProductRemove = (device, product) => {
        device.ProductsToBeAttached = device.ProductsToBeAttached.filter(prod => prod.ProductId !== product.ProductId);

        let devices = this.state.devices;
        let index = devices.findIndex(item => item.id === device.id);
        if (index > -1) {
            devices[index] = device;
        }
        this.setState({
            devices: devices
        });
    }

    onProductsReset = () => {
        this.setState({
            popupType:'Reset'
        });
        this.togglePopup();
    }

    getSelectedBundle = (devices) => {
        let selectedBundle = [];
        devices && devices.forEach(device => {
            if (device.ProductsToBeAttached.length > 0 || device.ProductsToBeDetached.length > 0) {
                selectedBundle.push(device);
            }
        })
        return selectedBundle;
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    onSubmitClick = () => {
        if (!this.getProductThresholdStatus()) {
            this.props.showAlert({
                type: AlertsTypes.ERROR, isGeneric: true, feature: AlertsFeature.GLOBAL_ALERTS,
                message: ["Maximum Attach/deatch should not exceed " + XBOXThreshold.PRODUCT]
            })
            return;
        }
        this.setState({
            popupType:'Submit'
        });
        this.togglePopup();
    }

    onProceed = () => {
        this.togglePopup();
        if(this.state.popupType == 'Reset')
        {
            let devices = this.state.devices;
            devices.forEach(device => {
                device.ProductsToBeDetached = [];
                device.ProductsToBeAttached = [];
                device.Attachments.forEach(prod => {
                    prod.selected = false;
                    prod.OperationType = TRANSACTION_TYPE[2];
                })
            })
            this.setState({
                devices: devices
            });
        }
        else if(this.state.popupType == 'Submit')
        {
            let wizardState = this.state.wizardState;
            let selectedBundle = this.getSelectedBundle(this.state.devices);
            wizardState.isStateValid = true;
            this.props.actions.saveManageDeviceSelection(selectedBundle);
        }
    }

    getProductThresholdStatus = () => {
        let selectedDevices = this.getSelectedBundle(this.state.devices);
        let totalProducts = 0;
        selectedDevices && selectedDevices.forEach(item => {
            let productToAttachCount = (!isNullOrUndefined(item.ProductsToBeAttached) && item.ProductsToBeAttached.length > 0) ? item.ProductsToBeAttached.length : 0;
            let productToDetachCount = (!isNullOrUndefined(item.ProductsToBeDetached) && item.ProductsToBeDetached.length > 0) ? item.ProductsToBeDetached.length : 0;
            totalProducts = totalProducts + productToAttachCount + productToDetachCount;
        });
        return totalProducts <= XBOXThreshold.PRODUCT;
    }

    onPreviousClick = () => {
        let wizardState = this.state.wizardState;
        wizardState.isStateValid = true;
        let selectedBundle = this.getSelectedBundle(this.state.devices);
        this.props.actions.setManageDeviceSelection(selectedBundle);
        this.props.manageDeviceActions.manageDeviceWizardStepPrevious(wizardState);
    }

    onHomeClick = () => {
        this.props.actions.destroyManageDeviceStoreData(true);
        history.push('/Home');
    }

    onNewDetachRequestClick = () => {
        this.props.actions.destroyManageDeviceStoreData(true);
    }

    submitBtnEnable = () => {
        return !(this.getSelectedBundle(this.state.devices).length > 0);
    }

    onExpandCollapse = () => {
        let isExpand = !this.state.isExpand;

        let devices = this.state.devices;

        devices && devices.forEach(device => {
            device.isExpand = isExpand;
        })
        this.setState({
            devices: devices,
            isExpand: isExpand
        })
    }

    onExpandCollapseItem = (id, toggleStatus) => {
        let devices = this.state.devices;
        let index = devices.findIndex(item => item.id === id);
        if (index > -1) {
            devices[index].isExpand = toggleStatus;
        }
        let isExpand = devices.filter(item => item.isExpand === true).length === devices.length;
        this.setState({
            devices: devices,
            isExpand: isExpand
        });
    }

    getResetStatus = () =>{
        let items = this.state.devices.filter(item => (item.ProductsToBeAttached && item.ProductsToBeAttached.length>0) || 
            (item.ProductsToBeDetached && item.ProductsToBeDetached.length>0))
        return items.length===0;
    }

    render() {
        return (
            <div className="device-product-selection-step-container">
                {this.props.manageDeviceResponse ? <DeviceTransactionStatus
                    onHomeClick={this.onHomeClick}
                    onNewDetachRequestClick={this.onNewDetachRequestClick}
                    transactionResponse={this.props.manageDeviceResponse} /> :
                    <React.Fragment>
                        <div className="floatRight textAlign-Right add-products-link ms-sm12 ms-md12 ms-lg7 ms-xl6 ">
                            <span className="expand-collapse">
                                <Link id="expand-collapse-click" title={this.state.isExpand ?
                                    this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Collapse', defaultMessage: "Collapse" }) :
                                    this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Expand', defaultMessage: "Expand" })}
                                    autoFocus={true}
                                    aria-label={this.state.isExpand ?
                                        this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Collapse', defaultMessage: "Collapse" }) :
                                        this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Expand', defaultMessage: "Expand" })}
                                    onClick={this.onExpandCollapse}>
                                    {this.state.isExpand ?
                                        <i className={"ms-Icon ms-Icon--CollapseContent collapse-icon"} aria-hidden="true"></i> :
                                        <i className={"ms-Icon ms-Icon--ExploreContent collapse-icon"} aria-hidden="true"></i>
                                    }


                                    {this.state.isExpand ?
                                        this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Collapse', defaultMessage: "Collapse" }) :
                                        this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Expand', defaultMessage: "Expand" })}
                                </Link>
                            </span>
                            <span className="reset-products">
                                <Link disabled={this.getResetStatus()} id="reset-product-link" title={this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.ResetProductLink', defaultMessage: "Reset Products" })}
                                    autoFocus={true}
                                    aria-label={`Reset`}
                                    onClick={this.onProductsReset}>
                                    <i className={"ms-Icon ms-Icon--RevToggleKey reset-icon"} aria-hidden="true"></i>
                                    {this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.AddProductLink', defaultMessage: "Reset" })}
                                </Link>
                            </span>
                            <Link
                                title='Add Products'
                                id={'add-product-link'}
                                autoFocus={true}
                                aria-label={`Add Products`}
                                onClick={this.onAddProductClick}>
                                <i className={"ms-Icon ms-Icon--CircleAdditionSolid  add-store-icon removepadding "} aria-hidden="true"></i>
                                {this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.AddProductLink', defaultMessage: "Add Products" })}

                            </Link>
                        </div>
                        <WizardStep>

                            <WizardStepHeader title={this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.headerTitle', defaultMessage: 'Manage Attachments' })} />
                            <WizardStepBody>
                                <div className="floatRight textAlign-Right add-products-mobile-link ms-sm12 ms-md12 ms-lg7 ms-xl6 ">
                                    <span className="expand-collapse">
                                        <Link id="expand-collapse-options" title={this.state.isExpand ?
                                            this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Collapse', defaultMessage: "Collapse" }) :
                                            this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Expand', defaultMessage: "Expand" })}
                                            autoFocus={true}
                                            aria-label={this.state.isExpand ?
                                                this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Collapse', defaultMessage: "Collapse" }) :
                                                this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Expand', defaultMessage: "Expand" })}
                                            onClick={this.onExpandCollapse}>
                                            {this.state.isExpand ?
                                                <i className={"ms-Icon ms-Icon--CollapseContent collapse-icon"} aria-hidden="true"></i> :
                                                <i className={"ms-Icon ms-Icon--ExploreContent collapse-icon"} aria-hidden="true"></i>
                                            }


                                            {this.state.isExpand ?
                                                this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Collapse', defaultMessage: "Collapse" }) :
                                                this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Expand', defaultMessage: "Expand" })}
                                        </Link>
                                    </span>
                                    <span className="reset-products">
                                        <Link id="reset-products-link" title={this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.ResetProductLink', defaultMessage: "Reset Products" })}
                                            autoFocus={true}
                                            aria-label={`Reset`}
                                            onClick={this.onProductsReset}>
                                            <i className={"ms-Icon ms-Icon--RevToggleKey reset-icon"} aria-hidden="true"></i>
                                            {this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.AddProductLink', defaultMessage: "Reset" })}
                                        </Link>
                                    </span>
                                    <Link
                                        title='Add Products'
                                        id={'add-products-link'}
                                        autoFocus={true}
                                        aria-label={`Add Products`}
                                        onClick={this.onAddProductClick}>
                                        <i className={"ms-Icon ms-Icon--CircleAdditionSolid  add-store-icon removepadding "} aria-hidden="true"></i>
                                        {this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.AddProductLink', defaultMessage: "Add Products" })}

                                    </Link>
                                </div>
                                <div className="sku-console-heading ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl4 pagesubHeading border-Bottom paddTop10 paddBtm5 paddLftRgt0 ">
                                    {this.props.searchBy === 'SKU' ? 'SKU' : 'Console'} {this.props.intl.formatMessage({ id: 'deviceProductDetails.details', defaultMessage: 'Details' })}
                                </div>
                                <div className="store-product-heading removepadding ms-Grid-col ms-sm12 ms-md6 ms-lg8 ms-xl8 pagesubHeading border-Bottom paddTop10 paddBtm5 paddRgt0 paddLft30">
                                    {this.props.intl.formatMessage({ id: 'deviceProductDetails.subTitle', defaultMessage: 'Store Products' })}
                                </div>
                                {
                                    this.state.devices &&
                                    <Iterator Component={DeviceProductMapping}
                                        onProductSelectionChange={this.handleChange}
                                        onProductRemove={this.onProductRemove}
                                        onExpandCollapse={this.onExpandCollapseItem}
                                        items={this.state.devices} />
                                }
                            </WizardStepBody>
                            <WizardStepFooter>
                                <DefaultButton
                                    className="previousButton"
                                    primary={true}
                                    data-automation-id="test"
                                    disabled={false}
                                    text={this.props.intl.formatMessage({ id: 'app.previous', defaultMessage: "Previous" })}
                                    iconProps={{ iconName: IconConstants.ChevronLeftSmall }}
                                    onClick={() => { this.onPreviousClick() }}
                                    allowDisabledFocus={true} />
                                <DefaultButton
                                    autoFocus={true}
                                    id={"submitButton"}
                                    className="iconRight floatRight"
                                    primary={true}
                                    data-automation-id="test"
                                    disabled={this.submitBtnEnable()}
                                    text={this.props.intl.formatMessage({ id: 'app.submit', defaultMessage: "Submit" })}
                                    iconProps={{ iconName: IconConstants.Save }}
                                    onClick={() => { this.onSubmitClick() }}
                                    allowDisabledFocus={false} />
                            </WizardStepFooter>
                        </WizardStep>
                        {
                            this.state.showDeviceProductSelectionPanel &&
                            <DeviceProductSelection showDeviceProductSelectionPanel={this.state.showDeviceProductSelectionPanel}
                                devices={this.state.devices}
                                hidePanel={this.hidePanel}
                                changeDeviceProductSelection={this.changeDeviceProductSelection} />
                        }
                        {this.state.showPopup &&
                            <ConfirmationPopup
                                isOpen={true}
                                confirmationTitle= { this.state.popupType == "Reset"  ?
                                    this.props.intl.formatMessage({ id: 'manageDevice.popupResetTitle', defaultMessage: "Store Products Reset Confirmation" }) :
                                    this.props.intl.formatMessage({ id: 'manageDevice.popupTitle', defaultMessage: "Device Attachment Confirmation" })
                                }
                                confirmationMessage= { this.state.popupType == "Reset"  ?
                                    this.props.intl.formatMessage({ id: 'manageDevice.popupResetText', defaultMessage: "You are about clear all the selected Store Products." }) :
                                    this.props.intl.formatMessage({ id: 'manageDevice.popupText', defaultMessage: "You are about to Attach/Detach the selected Store Products." })}
                                onDismiss={this.togglePopup.bind(this)}
                                onProceed={this.onProceed.bind(this)} />
                        }
                    </React.Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let initialSearchState = searchCriteriaSelector(state);
    let searchBy = initialSearchState ? initialSearchState.searchCriteria.SearchBy.value : null
    return {
        devices: selectedDeviceSelector(state),
        selectedBundle: selectedBundleSelector(state),
        searchBy: searchBy,
        manageDeviceResponse: manageDeviceResponseSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        manageDeviceActions: bindActionCreators(manageDeviceActions, dispatch),
        actions: bindActionCreators(deviceProductSelectionStepActions, dispatch),
        showAlert: bindActionCreators(showAlert, dispatch)
    }
}

DeviceProductSelectionStep.propTypes = propTypes;

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeviceProductSelectionStep));