import { take, put, call, apply } from 'redux-saga/effects';
import { InvokeUrl } from '@ec-oem/ec.oem.oa3.ui.common/utility';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger/Logger.actions';
import * as deviceProductSelectionAction from './DeviceProductSelectionStep.actions';
import { SEARCH_TYPES } from '../../../common/constants';
import { destroySearch } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { setPanelStoreProduct, setPanelSelectedStoreProduct } from '../../components';
import { TransactionStatus, deviceToTransactionRequest } from '../../../common/models';
import ApplicationConfig from '../../../common/utility/applicationConfig';
import * as manageDeviceActions from '../ManageDeviceAttachments/ManageDeviceAttachments.actions';
import * as ConsoleSelectionActions from '../DeviceSelectionStep/DeviceSelectionStep.actions';
import { showResponseMessage } from '../../../common/actions'

export function* saveManageDeviceSelectionSaga() {
    while (true) {
        try {
            let { manageDeviceSelection } = yield take(deviceProductSelectionAction.SAVE_MANAGEDEVICE_SELECTION);            
            let transactionRequest = deviceToTransactionRequest(manageDeviceSelection);
            let requestData = JSON.stringify(transactionRequest);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/transaction`, method: "POST", data: requestData }, SagaHandleResponse: { HandleResponse: false } });

            if (response.status === 201) {
                const transaction = yield apply(response, response.json);
                if (transaction) {
                    let transactionResponse = new TransactionStatus(transaction);
                    yield put(deviceProductSelectionAction.setManageDeviceResponse(transactionResponse));
                }
            }
            else {
                yield put(deviceProductSelectionAction.setManageDeviceResponse(null));
                yield put(showResponseMessage(response));
            }
        } catch (error) {
            yield put(throwException("saveManageDeviceSelectionSaga error: " + error));
            let errorObj = {
                statusText: "Something went wrong. Please contact OEM helpdesk for more assistance."
            }
            yield put(showResponseMessage(errorObj)); 
        }
    }
}

export function* destroyProductSearchResultSaga() {
    while (true) {
        try {
            yield take(deviceProductSelectionAction.DESTROY_PRODUCT_SEARCH_RESULT);
            yield put(destroySearch(SEARCH_TYPES.PRODUCT_SELECTION));
            //PRODUCT DATA
            yield put(setPanelStoreProduct(null));
            yield put(setPanelSelectedStoreProduct(null));
        }
        catch (error) {
            yield put(throwException("destroyProductSearchResultSaga error: " + error));
        }
    }
}
export function* destroyManageDeviceStoreDataSaga() {
    while (true) {
        try {
            let { clearAllStoreData } = yield take(deviceProductSelectionAction.DESTROY_MANAGEDEVICE_STORE_DATA);

            //Search Result Data
            yield put(destroySearch(SEARCH_TYPES.SKU_SEARCH));
            yield put(destroySearch(SEARCH_TYPES.CONSOLE_SEARCH));

            //Console Data            
            yield put(ConsoleSelectionActions.setSearchCriteria(null));
            yield put(ConsoleSelectionActions.setSelectedDevice(null));

            //Store Product Data            
            yield put(deviceProductSelectionAction.setManageDeviceSelection(null));

            if (clearAllStoreData) {
                //ManageDevice Response Data
                yield put(deviceProductSelectionAction.setManageDeviceResponse(null));

                //Reset wizard step
                yield put(manageDeviceActions.getManageDeviceWizardData());
            }
        }
        catch (error) {
            yield put(throwException("destrorManageDeviceStoreDataSaga error: " + error));
        }
    }
}