import React, { Component } from 'react';
import { Iterator } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { DeviceProductDetails } from '../DeviceProductDetails';
import { Link ,Label } from 'office-ui-fabric-react';
import './DeviceProductList.scss';

const propTypes = {
    deviceType: PropTypes.string,
    deviceProducts: PropTypes.array,
    transactionRecordId: PropTypes.string,
    hideAttachments: PropTypes.bool
}

class DeviceProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpand: true,
            expandCollapseStatusList: []
        }
    }

    onExpandCollapse = () => {
        let isExpand = !this.state.isExpand;

        let expandCollapseStatusList = [];
        this.props.deviceProducts.forEach(item => {
            expandCollapseStatusList.push({
                id: item.Device && item.Device.id,
                expandCollapseStatus: isExpand
            })
        });
        this.setState({
            expandCollapseStatusList: expandCollapseStatusList,
            isExpand: isExpand
        })
    }

    onExpandCollapseItem = (id, toggleStatus) => {
        let expandCollapseStatusList = this.state.expandCollapseStatusList ? this.state.expandCollapseStatusList : [];
        let index = expandCollapseStatusList.findIndex(item => item.id === id);
        if (index > -1) {
            expandCollapseStatusList[index].expandCollapseStatus = toggleStatus;
        }
        else {
            expandCollapseStatusList.push({
                id: id,
                expandCollapseStatus: toggleStatus
            })
        }
        let isExpand = expandCollapseStatusList.filter(item => item.expandCollapseStatus === true).length === this.props.deviceProducts.length;
        this.setState({
            expandCollapseStatusList: expandCollapseStatusList,
            isExpand: isExpand
        });
    }

    getDeviceProducts = (deviceProducts) => {
        deviceProducts && deviceProducts.forEach(device => {
            let expandCollapseStatus = this.state.expandCollapseStatusList.find(item => item.id === device.Device.id);
            if (expandCollapseStatus) {
                device.isExpand = expandCollapseStatus.expandCollapseStatus;
            }
        })
        return deviceProducts;
    }

    render() {
        let deviceProducts = this.props.deviceProducts;
        return (
            <div className="device-product-list">
                {
                    deviceProducts && deviceProducts.length > 0 &&
                        deviceProducts[0].Device && deviceProducts[0].ProductTransactions ?
                        <div>
                            <div className="floatRight textAlign-Right link-items ms-sm12 ms-md12 ms-lg12 ms-xl12 ">
                                <span className="expand-collapse">
                                    <Link id="expand-collapse-link" title={this.state.isExpand ?
                                        this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Collapse', defaultMessage: "Collapse" }) :
                                        this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Expand', defaultMessage: "Expand" })}
                                        autoFocus={true}
                                        aria-label={`Reset`}
                                        onClick={this.onExpandCollapse}>
                                        {this.state.isExpand ?
                                            <i className={"ms-Icon ms-Icon--CollapseContent collapse-icon"} aria-hidden="true"></i> :
                                            <i className={"ms-Icon ms-Icon--ExploreContent collapse-icon"} aria-hidden="true"></i>
                                        }
                                        {this.state.isExpand ?
                                            this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Collapse', defaultMessage: "Collapse" }) :
                                            this.props.intl.formatMessage({ id: 'deviceProductSelectionStep.Expand', defaultMessage: "Expand" })}
                                    </Link>
                                </span>
                            </div>
                            <div className="sku-console-heading ms-Grid-col ms-sm12 ms-md12 ms-lg4 ms-xl4 pagesubHeading border-Bottom  paddBtm5 paddLftRgt0">{this.props.deviceType === 'SKU' ? 'SKU' : 'Console'} Details</div>
                            <div className="store-product-heading removepadding ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-xl8 pagesubHeading border-Bottom paddBtm5 paddRgt0 paddLft30">Store Products</div>

                            <Iterator
                                DeviceType={this.props.deviceType}
                                Component={DeviceProductDetails}
                                disableSelection={true}
                                transactionRecordId={this.props.transactionRecordId}
                                items={this.getDeviceProducts(this.props.deviceProducts)}
                                onExpandCollapse={this.onExpandCollapseItem}
                                hideAttachments={this.props.hideAttachments} />
                        </div> :
                        <div className="ms-sm12 ms-md12 ms-lg12 ms-xl12 ">
                            <Label className="unAvailableProduct">
                            {
                                this.props.intl.formatMessage({ id: 'transactionDetails.noResultFound', defaultMessage: "No Result Found" })
                            }
                            </Label>
                        </div>
                }
            </div>
        )
    }
}

DeviceProductList.propTypes = propTypes;
export default injectIntl(DeviceProductList);


