import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility";

export const DESTROY_STORE_DATA = "common/components/DeviceSelectionStep/DESTROY_STORE_DATA";
export const destroyStoreData = makeActionCreator(DESTROY_STORE_DATA, false);

export const GET_SEARCH_BY_CONSOLE_RESULT = "common/components/DeviceSelectionStep/GET_SEARCH_BY_CONSOLE_RESULT";
export const getSearchByConsoleResult = makeActionCreator(GET_SEARCH_BY_CONSOLE_RESULT, false, "searchCriteria", "isSelectionAllowed");

export const GET_SEARCH_BY_SKU_RESULT = "common/components/DeviceSelectionStep/GET_SEARCH_BY_SKU_RESULT";
export const getSearchBySKUResult = makeActionCreator(GET_SEARCH_BY_SKU_RESULT, false, "searchCriteria", "isSelectionAllowed");

export const SET_SEARCH_CRITERIA = "common/components/DeviceSelectionStep/SET_SEARCH_CRITERIA";
export const setSearchCriteria = makeActionCreator(SET_SEARCH_CRITERIA, false, "searchCriteria");

export const SET_SELECTED_CONSOLE_SKU = "common/components/DeviceSelectionStep/SET_SELECTED_CONSOLE_SKU";
export const setSelectedDevice = makeActionCreator(SET_SELECTED_CONSOLE_SKU, false, "selectedDevice");
