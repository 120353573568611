import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { DeviceFilter } from '../DeviceFilter';
import * as consoleSelectionStepSelectors from '../../components/DeviceSelectionStep/DeviceSelectionStep.selectors';
import { ConsoleCard } from '../../../common/components';
import { InMemorySearchResult, SearchDefaultSelectionTypes } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { SEARCH_TYPES } from '../../../common/constants';

const consoleMappers = {}

const propTypes = {
    intl: intlShape.isRequired,
    searchCriteria: PropTypes.object,
    allowItemSelection: PropTypes.bool
}

const getDefaultSelections = () => {
    return {
        [SearchDefaultSelectionTypes.PAGING_OPTION]: {
            page: 1,
            pageSize: 12
        },
        [SearchDefaultSelectionTypes.SELECTED_FILTERS]: null,
        [SearchDefaultSelectionTypes.SELECTED_ITEMS]: null,
        [SearchDefaultSelectionTypes.SORT_OPTION]: null
    }
}

class ConsoleSearchResult extends React.Component {

    getSearchInputs = (searchCriteria) => {
        let searchInputs = [];

        if (searchCriteria && searchCriteria.SearchBy && searchCriteria.SearchBy.value) {
            let input = {
                label: this.props.intl.formatMessage({ id: 'selectConsole.deviceType', defaultMessage: 'Device Type' })
            }
            input.value = searchCriteria.SearchBy.text;
            searchInputs.push(input);

            if (searchCriteria.SerialNumber && searchCriteria.SerialNumber.value) {
                input = {
                    label: this.props.intl.formatMessage({ id: 'selectConsole.serialNumber', defaultMessage: 'Serial Number' })
                }
                input.value = searchCriteria.SerialNumber.value;
                searchInputs.push(input);
            }
        }
        return searchInputs;
    }

    render() {
        return (
            <React.Fragment>
                <div className="ms-Grid select-console removepadding">
                    <DeviceFilter allowItemSelection={this.props.allowItemSelection !== undefined ? this.props.allowItemSelection : false}></DeviceFilter>
                </div>
                <InMemorySearchResult searchName={SEARCH_TYPES.CONSOLE_SEARCH}
                    searchCriteria={this.getSearchInputs(this.props.searchCriteria)}
                    defaultSelections={getDefaultSelections()}
                    ResultIteratorComponent={ConsoleCard}
                    allowItemSelection={this.props.allowItemSelection !== undefined ? this.props.allowItemSelection : true}
                    mappers={consoleMappers}></InMemorySearchResult>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    let initialSearchState = consoleSelectionStepSelectors.searchCriteriaSelector(state);
    let searchCriteria = initialSearchState ? initialSearchState.searchCriteria : null;

    return {
        searchCriteria: searchCriteria
    };
}

ConsoleSearchResult.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, null)(ConsoleSearchResult));