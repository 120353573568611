import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Label, Link } from 'office-ui-fabric-react';
import './DeviceTransactionStatus.scss';
import { DefaultButton } from 'office-ui-fabric-react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import history from '../../../common/utility/history';
import * as bundleAction from '../DeviceProductSelectionStep/DeviceProductSelectionStep.actions';

const propTypes = {
    onHomeClick: PropTypes.func,
    intl: intlShape.isRequired,
    transactionResponse: PropTypes.object,
    onNewDetachRequestClick: PropTypes.func
}

class DeviceTransactionStatus extends Component {
    componentDidMount() {
        this.props.bundleAction.destroyManageDeviceStoreData(false);
    }
    handleClick = () => {
        let path = `/TransactionDetails/${this.props.transactionResponse.Id}`;
        this.props.bundleAction.destroyManageDeviceStoreData(true);
        history.push(path);
    }
    render() {
        return (
            <div className="order-confirmation">
                <div className="status-msg-header" aria-live="assertive">
                    {this.props.intl.formatMessage({ id: 'app.transactionSubmissionMsg', defaultMessage: "Transaction Submitted Successfully!" })}
                </div>

                <Label className="status-msg"> {this.props.intl.formatMessage({ id: 'app.msgBundle', defaultMessage: "Your Bundle #" })}
                    <Link id={'TransactionLink'}
                        autoFocus={true}
                        aria-label={`ID Generated is:${this.props.transactionResponse.TransactionId}. Hit enter to see the trasanction details.`}
                        onClick={this.handleClick}>
                        {this.props.transactionResponse.TransactionId} </Link> {this.props.intl.formatMessage({ id: 'app.msgDetachStatus', defaultMessage: " has been submitted successfully." })}
                </Label>
                <div className="ms-Grid-row split-button">
                    <DefaultButton
                        className="homeIcon"
                        primary={false}
                        disabled={false}
                        aria-label={this.props.intl.formatMessage({ id: 'app.homeButton', defaultMessage: "Click Enter for Home Page" })}
                        text={this.props.intl.formatMessage({ id: 'app.home', defaultMessage: "HOME" })}
                        onClick={this.props.onHomeClick}
                        allowDisabledFocus={true} />
                    <DefaultButton
                        className="bundleIcon"
                        primary={true}
                        disabled={false}
                        aria-label={this.props.intl.formatMessage({ id: 'app.newDetachRequestButton', defaultMessage: "Click Enter to create attach/deatch Request" })}
                        text={this.props.intl.formatMessage({ id: 'app.newDetachRequest', defaultMessage: "NEW ATTACH/DETACH REQUEST" })}
                        onClick={this.props.onNewDetachRequestClick}
                        allowDisabledFocus={true} />
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        bundleAction: bindActionCreators(bundleAction, dispatch),
    }
}
DeviceTransactionStatus.propTypes = propTypes
export default injectIntl(connect(null, mapDispatchToProps)(DeviceTransactionStatus)); 