import * as React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'office-ui-fabric-react';
import { Card, CardBody } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { TabIndexes, KeyBoardKeys } from '@ec-oem/ec.oem.oa3.ui.common/constants';
import { BLANK_PLACEHOLDER } from '../../constants/XBOXConstants';
import { injectIntl } from 'react-intl';
import { getDateTimeString } from '../../utility/dateMapper';
import history from '../../../common/utility/history';
import "./TransactionCard.scss"

const propTypes = {
    disableSelection: PropTypes.bool,
    TransactionType: PropTypes.string,
    Status: PropTypes.string,
    TransactionDate: PropTypes.any,
    SubmittedBy: PropTypes.string
}

class TransactionCard extends React.Component {

    handleCardClick = () => {
        if (!this.props.disableSelection) {
            let path = `/TransactionDetails/${this.props.TransactionId}`;
            history.push(path);
        }
    }

    handleCardKeyDown = (e) => {
        if (e.keyCode === KeyBoardKeys.Enter || e.keyCode === KeyBoardKeys.Space) {
            e.preventDefault();
            this.handleCardClick();
        }
    }

    getCardClassName = () => {
        if (!this.props.disableSelection) {
            if (this.props.selected) {
                return "Selected"
            }
            else
                return ""
        }
    }

    getPointerClassName = () => {
        if (!this.props.disableSelection) {
            return "pointer-cursor"
        }
        else
            return ""
    }

    getMainCardAlignment = (alignType) => {
        if (alignType === "Horizontal")
            return "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 transaction-info";
        else
            return "ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl4 transaction-card";
    }

    getHorizontalAlignment = (alignType, sectionAlign) => {
        if (alignType === "Horizontal") {
            if (sectionAlign == "card-info-section")
                return "ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl4 removepadding";
            else
                return "ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6 removepadding";
        }
        else if (sectionAlign == "card-sub-section")
            return "ms-Grid-col ms-smPush1 ms-mdPush1  ms-lgPush1  ms-sm5 ms-md5 ms-lg5 removepadding";
        else
            return "ms-Grid-row card-row";
    }

    render() {
        const { index } = this.props;
        let cardClassName = this.getCardClassName();
        let pointerClassName = this.getPointerClassName();
        let cardMainAlignmentClassName = this.getMainCardAlignment(this.props.alignType);
        let sectionClassName = this.getHorizontalAlignment(this.props.alignType, "card-info-section");
        let cardHorizontalClassName = this.getHorizontalAlignment(this.props.alignType, "card-sub-section");
        return (

            <div className={"search-card  search-transaction-card " + cardClassName + " " + pointerClassName + " removepadding " + cardMainAlignmentClassName}
                tabIndex={this.props.disableSelection ? TabIndexes.OutOfOrder : TabIndexes.InOrder}
                onKeyDown={(e) => this.handleCardKeyDown(e)}
                onClick={() => this.handleCardClick()}>
                <Card>
                    <CardBody>
                        <div className={sectionClassName}>
                            <div className={cardHorizontalClassName}>
                                <Label name="TransactionIdLabel" id={`TransactionIdLabel${index}`} className={"label-header " + pointerClassName} >
                                    {this.props.intl.formatMessage({ id: 'searchTransaction.transactionId', defaultMessage: 'Transaction Id' })}
                                </Label>
                                <Label title={this.props.TransactionNo ? this.props.TransactionNo : BLANK_PLACEHOLDER} name="TransactionId" id={`TransactionId${index}`} className={"transaction-id-value label-value " + pointerClassName}>
                                    {this.props.TransactionNo ? this.props.TransactionNo : BLANK_PLACEHOLDER}</Label>
                            </div>
                            <div className={cardHorizontalClassName}>
                                <Label name="DeviceTypeLabel" id={`DeviceTypeLabel${index}`} className={"label-header " + pointerClassName} >
                                    {this.props.intl.formatMessage({ id: 'searchTransaction.deviceType', defaultMessage: 'Device Type' })}
                                </Label>
                                <Label name="DeviceTypevalue" id={`DeviceTypevalue${index}`} className={"label-value " + pointerClassName}>
                                    {this.props.DeviceType ? this.props.DeviceType : BLANK_PLACEHOLDER}</Label>
                            </div>

                        </div>

                        <div className={sectionClassName}>
                            <div className={cardHorizontalClassName}>
                                <Label name="TransactionStatus" id={`TransactionStatus${index}`} className={"label-header " + pointerClassName} >
                                    {this.props.intl.formatMessage({ id: 'searchTransaction.transactionStatus', defaultMessage: 'Transaction Status' })}
                                </Label>
                                <Label name="TransactionStatusValue" id={`TransactionStatusValue${index}`} className={"label-value " + pointerClassName}>
                                    {this.props.Status ? this.props.Status : BLANK_PLACEHOLDER}</Label>
                            </div>
                            <div className={cardHorizontalClassName}>
                                <Label name="TransactionDateLabel" id={`TransactionDate${index}`} className={"label-header " + pointerClassName}>
                                    {this.props.intl.formatMessage({ id: 'searchTransaction.transactionDate', defaultMessage: 'Transaction Date' })}
                                </Label>
                                <Label name="TransactionDateValue" id={`TransactionDateValue${index}`} className={"label-value " + pointerClassName}>{this.props.TransactionDate ? getDateTimeString(this.props.TransactionDate) : BLANK_PLACEHOLDER}</Label>
                            </div>
                        </div>
                        <div className={sectionClassName + " no-border-bottom"}>
                            <div className={cardHorizontalClassName}>
                                <Label name="SubmittedByLabel" id={`SubmittedByLabel${index}`} className={"label-header " + pointerClassName}>
                                    {this.props.intl.formatMessage({ id: 'searchTransaction.transactionSubmittedBy', defaultMessage: 'Submitted By' })}
                                </Label>
                                <Label name="SubmittedByValue" id={`SubmittedByValue${index}`} className={"label-value " + pointerClassName}>
                                    {this.props.SubmittedBy ? this.props.SubmittedBy : BLANK_PLACEHOLDER}</Label>
                            </div>
                            <div className={cardHorizontalClassName}>

                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

TransactionCard.propTypes = propTypes
export default injectIntl(TransactionCard);

