import * as React from 'react';
import { injectIntl } from 'react-intl';
import { SKUCard, ConsoleCard, StoreProductCard } from "../../../common/components";
import { Label } from 'office-ui-fabric-react';
import { Iterator } from '@ec-oem/ec.oem.oa3.ui.core/components/Iterator';
import { PropTypes } from 'prop-types';
import { Accordion, AccordionHeader, AccordionBody } from '@ec-oem/ec.oem.oa3.ui.core/components/Accordion';
import './DeviceProductMapping.scss';
import { DEVICE_TYPE } from '../../../common/constants';

const propTypes = {
    onProductSelectionChange: PropTypes.func,
    Attachments: PropTypes.array,
    DeviceType: PropTypes.string,
    onProductRemove: PropTypes.func,
    onExpandCollapse: PropTypes.func
}

class DeviceProductMapping extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectAll: false
        }
    }

    componentDidMount() {
    }
    onItemClick = (item) => {
        let products = this.props.Attachments;
        let product = products.find(prod => prod.id === item.id);
        if (product) {
            product.selected = !product.selected;
        }
        this.props.onProductSelectionChange && this.props.onProductSelectionChange(this.props.id, product);
    }

    onSelectAllChange = (e, checked) => {
        let products = this.props.Attachments;
        products.forEach(prod => {
            prod.selected = checked;
        })
        this.props.onProductSelectionChange && this.props.onProductSelectionChange({ ...this.props });
    }

    onItemRemove = (product) => {
        this.props.onProductRemove && this.props.onProductRemove({ ...this.props }, product);
    }

    onAccordionToggle = (toggleStatus) => {
        this.props.onExpandCollapse && this.props.onExpandCollapse(this.props.id, toggleStatus);
    }


    render() {
        return (
            <div className="device-product-mapping">
                <Accordion isOpen={this.props.isExpand} onAccordionToggle={this.onAccordionToggle}>
                    <AccordionHeader>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl4 sku-console-section-heading">
                            {this.props.DeviceType === DEVICE_TYPE.SKU ? this.props.DeviceName : this.props.SerialNumber}
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8 ms-xl8 store-count-section-heading">
                            <span>Attach: <span className="label-value attach-count">{this.props.ProductsToBeAttached ? this.props.ProductsToBeAttached.length : 0}  </span></span>
                            <span>Deatch: <span className="label-value deatch-count">{this.props.ProductsToBeDetached ? this.props.ProductsToBeDetached.length : 0}  </span></span>
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        <div className="ms-Grid ms-sm12 ms-md12 console-product-mapping selection-detach-flow">
                            <div className="sku-console-heading-each-row ms-Grid-col ms-sm12 ms-md12 pagesubHeading border-Bottom paddTop10 paddBtm5 paddLftRgt0 ">
                                {this.props.DeviceType === DEVICE_TYPE.SKU ? DEVICE_TYPE.SKU : 'Console'} Details
                            </div>

                            <div className={this.props.Attachments.length > 0 ? "select-all-option-available" : ""}>
                                {this.props.DeviceType === DEVICE_TYPE.SKU ?
                                    <SKUCard disableSelection={true} {...this.props} > </SKUCard> :
                                    <ConsoleCard disableSelection={true} {...this.props} > </ConsoleCard>
                                }
                            </div>
                            <div className="store-product-heading-each-row removepadding ms-Grid-col ms-sm12 ms-md12  pagesubHeading border-Bottom paddTop10 paddBtm5 paddRgt0">
                                {this.props.intl.formatMessage({ id: 'deviceProductDetails.subTitle', defaultMessage: 'Store Products' })}
                            </div>
                            {(this.props.Attachments.length > 0 || this.props.ProductsToBeAttached.length > 0) ?
                                <div className="storeproductdatalist ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-xl8 paddLft20 removepaddLft20 removepadding">
                                    <Iterator disableSelection={false} 
                                        deviceId={this.props.id}
                                        Component={StoreProductCard}
                                        onItemClick={this.onItemClick}
                                        items={this.props.Attachments} />

                                    <Iterator disableSelection={true}
                                        deviceId={this.props.id}
                                        Component={StoreProductCard}
                                        onItemChange={this.onItemRemove}
                                        items={this.props.ProductsToBeAttached} />
                                </div > :
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 ms-xl8 paddLft20 removepaddLft20">
                                    <Label className="unAvailableProduct">
                                        {this.props.intl.formatMessage({ id: 'deviceProductDetails.noStoreProduct', defaultMessage: 'No store product available' })}
                                    </Label>
                                </div>
                            }
                        </div>
                    </AccordionBody>
                </Accordion>
            </div>
        );
    }
}
DeviceProductMapping.propTypes = propTypes;
export default injectIntl(DeviceProductMapping);