export const getErrorMessage = (response) => {

    switch (response.status) {
        case 400:
            return "Bad Request - server can not process request.";
        case 401:
            return "Unauthorized - you are not authorized to perform this action";
        case 403:
            return "Forbidden - The request was valid, but the server is refusing action, you may not have the necessary permissions to perform this action";
        case 404:
            return "The requested resource not be found";
        case 408:
            return "Request Timeout - The server timed out waiting for the request";
        case 500:
            return "Internal Server Error";
        default:
            return response.statusText

    }
}
