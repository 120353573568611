'use strict';
import { deviceProductTransactionMapper } from './DeviceProductTransaction'
import { DEVICE_TYPE, DEVICE_IDENTIFIER_TYPE, TRANSACTION_TYPE, TRANSACTION_STATUS } from '../constants/XBOXConstants';

export class Transaction {
    constructor(transactionData) {
        this.TransactionId = transactionData.Id ? transactionData.Id
            : transactionData.TransactionId ? transactionData.TransactionId : '';
        this.TransactionRecordId = transactionData.TransactionRecordId ? transactionData.TransactionRecordId : ''
        this.TransactionNo = transactionData.TransactionNo ? transactionData.TransactionNo : null;
        this.TransactionType = transactionData.TransactionType ? transactionData.TransactionType.toUpperCase() : '';
        this.Status = transactionData.Status ? transactionData.Status : "";
        this.TransactionDate = transactionData.TransactionDate ? transactionData.TransactionDate : '';
        this.SubmittedBy = transactionData.SubmittedBy ? transactionData.SubmittedBy : '';
        this.DeviceProductTransactions = transactionData.DeviceProductTransactions ? transactionData.DeviceProductTransactions : null;
        this.DeviceType = transactionData.DeviceType;
        this.SkuDescription= transactionData.SkuDescription;
    }
}

export const transactionMapper = (details) => {
    let transactionStatus = details.Status ? TRANSACTION_STATUS.filter(st => st.key == details.Status) : [];
    if (details) {
        return new Transaction({
            TransactionId: details.TransactionId,
            TransactionRecordId: details.TransactionRecordId,
            TransactionNo: details.TransactionNo,
            TransactionType: TRANSACTION_TYPE[details.TransactionType],
            Status: details.Status ? transactionStatus[0] && transactionStatus[0].text : '',
            TransactionDate: details.TransactionDate,
            SubmittedBy: details.SubmittedBy,
            DeviceType: details.DeviceIdentifierType === DEVICE_IDENTIFIER_TYPE.SerialNumber ?
                        DEVICE_TYPE.CONSOLE : DEVICE_TYPE.SKU,
            DeviceProductTransactions: deviceProductTransactionMapper(details.DeviceProductTransactions),
            SkuDescription:details.SkuDescription
        })
    }
    else
        return null;
}