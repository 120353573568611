import React from "react";
import { SearchBox } from "office-ui-fabric-react";
import { injectIntl } from "react-intl";
import PropTypes from 'prop-types';

const propTypes = {
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  searchText: PropTypes.string
}

class StoreProductFilter extends React.Component {
  handleSearch = (searchText) => {
    this.props.onSearch && this.props.onSearch(searchText);
  }

  handleChange = (searchText) => {
    this.props.onChange && this.props.onChange(searchText);
  }

  render() {
    return (
      <div>
        <SearchBox
          placeholder={this.props.placeholder ? this.props.placeholder : 'Search'}
          className={this.props.className}
          onSearch={searchText => this.handleSearch(searchText)}
          onChange={searchText => this.handleChange(searchText)}
          value={this.props.searchText}
        />
      </div >
    );
  }
};
StoreProductFilter.propTypes = propTypes;
export default injectIntl(StoreProductFilter);
