import * as React from "react";
import { HoverCard, Link, HoverCardType } from "office-ui-fabric-react";
import { Image, ImageFit } from 'office-ui-fabric-react/lib/Image';
import { KeyCodes } from '@uifabric/utilities';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import './AttachmentCard.scss';

const AttachmentCard = (props) => {

  const onRenderPlainCard = () => {
    return (
      <div className="attachments-Card">
        <div className="card-title">
          <Link className="no-hover-link">{props.intl.formatMessage({ id: 'attachDetails.storeProductTitle', defaultMessage: 'Store Product' })} ( Total Count :  {props.attachments.length} )</Link>
        </div>
        {props.attachments.map((content, index) =>
          <div key={index} className="ms-Grid-row attachments-details">
            <div className="ms-Grid-col ms-sm3 product-image">
              <Image src={content.Icon} width={50} height={50} imageFit={ImageFit.cover} />
            </div>
            <div className="ms-Grid-col ms-sm9 store-product-name">
              <Link className="no-hover-link">
                {content.Title}
              </Link>
            </div>
          </div>)}
      </div>
    );
  }
  const rootStyle = { width: "300px" };
  const plainCardProps = {
    onRenderPlainCard: onRenderPlainCard,
    styles: { root: rootStyle }
  };

  if (props.attachments && props.attachments.length > 0) {
    return (
      <div>
        <HoverCard          
          plainCardProps={plainCardProps}
          instantOpenOnClick={true}
          type={HoverCardType.plain}
          trapFocus={true}
          openHotKey={KeyCodes.enter}
          aria-label={props.intl.formatMessage({ id: 'app.closeHoverCard', defaultMessage: "Click Esc button to get out of the Hover card"})}
        >
          {props.children}
        </HoverCard>
      </div>
    );
  }
}
AttachmentCard.propTypes = {
  attachments: PropTypes.array
}

export default injectIntl(AttachmentCard);

