import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { DeviceSelection, ProductSelection } from '../../components'
import { DefaultButton, Panel, PanelType } from 'office-ui-fabric-react';
import './DeviceProductSelection.scss';
import { selectedDeviceSelector } from '../DeviceSelectionStep';
import { PanelLoader } from '@ec-oem/ec.oem.oa3.ui.core/components'
import { SearchResultConstants, searchResultInfoItemSelector } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { SEARCH_TYPES, IconConstants } from '../../../common/constants';
import { isNullOrUndefined } from 'util';
import { destroyProductSearchResult } from '../DeviceProductSelectionStep/DeviceProductSelectionStep.actions';


const propTypes = {
    showDeviceProductSelectionPanel: PropTypes.bool
}

class DeviceProductSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wizardState: this.props.initialState,
            selectedDevices: this.props.selectedDevices

        }
    }

    onSave = () => {
        if (!isNullOrUndefined(this.state.selectedDevices) && !isNullOrUndefined(this.props.selectedProducts)) {
            this.props.changeDeviceProductSelection(this.state.selectedDevices, this.props.selectedProducts);
        }
        this.props.destroyProductSearchResult();
    }

    onCancel = () => {
        this.props.destroyProductSearchResult();
        this.props.hidePanel();
    }
    onItemClick = (selectedDevices) => {
        this.setState({
            selectedDevices: selectedDevices
        })
    }

    hidePanel = () => {
        this.props.destroyProductSearchResult();
        this.props.hidePanel();
    }

    onRenderHeader = () => {
        return (
            <div className="panel-header">
                {this.props.intl.formatMessage({ id: 'device.DeviceProductSelection.SelectProducts', defaultMessage: 'Select Products' })}
            </div>
        );
    }

    saveBtnEnable = () => {
        let selectedDevices = this.state.selectedDevices;
        let selectedProducts = this.props.selectedProducts;
        return (isNullOrUndefined(selectedDevices) || selectedDevices.length === 0 ||
            isNullOrUndefined(selectedProducts) || selectedProducts.length === 0);
    }

    onRenderFooter = () => {
        return (
            <div className="device-product-selection">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row panel-footer">

                        <DefaultButton
                            id="cancelButton"
                            className="cancel-Button ms-md3 ms-lg2"
                            primary={true}
                            text={this.props.intl.formatMessage({ id: 'app.Cancel', defaultMessage: 'Cancel' })}
                            iconProps={{ iconName: IconConstants.Cancel }}
                            onClick={() => this.onCancel()}
                            allowDisabledFocus={true} />

                        <DefaultButton
                            id="saveButton"
                            className="panel-primary-button  ms-md3 ms-lg2 floatRight product-save-button"
                            primary={true}
                            disabled={this.saveBtnEnable()}
                            text={this.props.intl.formatMessage({ id: 'app.Save', defaultMessage: 'Save' })}
                            iconProps={{ iconName: IconConstants.Save }}
                            onClick={() => this.onSave()}
                            allowDisabledFocus={false} />
                    </div>
                </div>
            </div>
        );
    }

    render() {

        return (
            <Panel
                className="store-product-additon-panel"
                isOpen={this.props.showDeviceProductSelectionPanel}
                type={PanelType.custom}
                customWidth="750px"
                onDismiss={this.hidePanel}
                isFooterAtBottom={true}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={() => this.onRenderFooter()}
                onRenderHeader={() => this.onRenderHeader()}>
                <div className="ms-panel-Overlay"><PanelLoader /></div>
                {this.props.selectedDevices && this.props.selectedDevices.length > 1 &&
                    <div className="sku-console-selection-panel" >
                        <DeviceSelection onItemClick={this.onItemClick} selectedDevices={this.props.selectedDevices} />
                    </div>
                }
                <div className="store-product-selection-panel" >
                    <ProductSelection></ProductSelection>
                </div>
            </Panel>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedDevices: selectedDeviceSelector(state),
        selectedProducts: searchResultInfoItemSelector(state, SEARCH_TYPES.PRODUCT_SELECTION, SearchResultConstants.SELECTED_ITEMS)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        destroyProductSearchResult: bindActionCreators(destroyProductSearchResult, dispatch)
    }
}

DeviceProductSelection.propTypes = propTypes;

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeviceProductSelection));