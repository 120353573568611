import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility";

export const SET_MANAGE_DEVICE_SELECTION = "device/components/DeviceProductSelectionStep/SET_MANAGE_DEVICE_SELECTION";
export const setManageDeviceSelection = makeActionCreator(SET_MANAGE_DEVICE_SELECTION, false, "manageDeviceSelection");

export const DESTROY_PRODUCT_SEARCH_RESULT = "device/components/DeviceProductSelectionStep/DESTROY_PRODUCT_SEARCH_RESULT";
export const destroyProductSearchResult = makeActionCreator(DESTROY_PRODUCT_SEARCH_RESULT, false);

export const SAVE_MANAGEDEVICE_SELECTION = "device/components/DeviceProductSelectionStep/SAVE_MANAGEDEVICE_SELECTION";
export const saveManageDeviceSelection = makeActionCreator(SAVE_MANAGEDEVICE_SELECTION, false, "manageDeviceSelection");

export const SET_MANAGEDEVICE_RESPONSE = "device/components/DeviceProductSelectionStep/SET_MANAGEDEVICE_RESPONSE";
export const setManageDeviceResponse = makeActionCreator(SET_MANAGEDEVICE_RESPONSE, false, "manageDeviceResponse");

export const DESTROY_MANAGEDEVICE_STORE_DATA = "device/components/DeviceProductSelectionStep/DESTROY_MANAGEDEVICE_STORE_DATA";
export const destroyManageDeviceStoreData = makeActionCreator(DESTROY_MANAGEDEVICE_STORE_DATA, false,"clearAllStoreData");
