import * as React from 'react';
import PropTypes from 'prop-types';
import { Label, Link } from 'office-ui-fabric-react';
import { Card, CardBody } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { TabIndexes, KeyBoardKeys } from '@ec-oem/ec.oem.oa3.ui.common/constants';
import { injectIntl } from 'react-intl';
import { KeyCodes } from '@uifabric/utilities';
import { AttachmentCard } from '../AttachmentCard';
import { Device } from '../../../common/models';
import { getFormattedDate } from '../../utility/dateMapper';
import ReactDOM from 'react-dom';
import { DEVICE_TYPE, BLANK_PLACEHOLDER } from '../../constants';

const propTypes = {
    disableSelection: PropTypes.bool,
    SerialNumber: PropTypes.string,
    ManufacturingDate: PropTypes.any,
    Attachments: PropTypes.any,
    hideAttachments: PropTypes.bool,
    disabled: PropTypes.bool,
    onItemClick: PropTypes.func,
    SkuDescription: PropTypes.string,
    selected: PropTypes.bool
}

class ConsoleCard extends React.Component {

    handleAttachmentsClick = (e) => {
        e.stopPropagation();
    }

    handleCardClick = () => {
        if (!this.props.disableSelection && !this.props.disabled) {
            let selectedItem = new Device({
                DeviceType: DEVICE_TYPE.CONSOLE,
                DeviceName: this.props.DeviceName,
                id: this.props.SerialNumber,
                SerialNumber: this.props.SerialNumber,
                ManufacturingDate: this.props.ManufacturingDate,
                ProductFamily: this.props.ProductFamily,
                Attachments: this.props.Attachments,
                Status: this.props.Status,
                disabled: this.props.disabled,
                SkuDescription: this.props.SkuDescription
            }, this.props.selected);
            this.props.onItemClick && this.props.onItemClick(selectedItem);
        }
    }

    handleCardKeyDown = (e) => {

        if (e.keyCode === KeyBoardKeys.Enter || e.keyCode === KeyBoardKeys.Space) {
            e.preventDefault();
            this.handleCardClick();
        }
        // else if(e.keyCode==KeyBoardKeys.Down)
        else if (e.keyCode === 40) {
            e.preventDefault();
            let linkNode = ReactDOM.findDOMNode(this.refs['tabIndexLink']);
            if(linkNode){
                linkNode.focus();
            }
            e.stopPropagation();
        }
    }

    handleLinkKeyDown = (e) => {
        if (e.keyCode === KeyCodes.enter || e.keyCode === KeyCodes.space) {
            e.preventDefault();
        }
        e.stopPropagation();
    }

    getCardClassName = () => {
        let cardClassName = 'search-card ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl4 removepadding ';
        if (!this.props.disableSelection) {
            cardClassName += this.props.disabled ? 'disable-card ' : 'pointer-cursor ';
            if (!this.props.disabled && this.props.selected) {
                cardClassName += 'selected';
            }
        }
        return cardClassName;
    }

    getAriaLabel = () => {
        let ariaLabel = this.props.intl.formatMessage({ id: 'selectConsole.selectCard', defaultMessage: 'Click tab for next card. Click down arrow for attachement.' });
        if (!this.props.disableSelection && !this.props.disabled && this.props.selected) {
            ariaLabel += this.props.intl.formatMessage({ id: 'selectConsole.selectedCard', defaultMessage: 'This card is Selected' });
        }
        else {
            ariaLabel += this.props.intl.formatMessage({ id: 'selectConsole.notSelectedCard', defaultMessage: 'This card is not Selected' });
        }
        return ariaLabel;
    }

    getPointerClassName = () => {
        return this.props.disableSelection ? '' : 'pointer-cursor';
    }

    render() {
        const { index } = this.props;
        let pointerClassName = this.getPointerClassName();
        return (

            <div id={`ConsoleCard${index}`} className={this.getCardClassName()}
                tabIndex={this.props.disableSelection ? TabIndexes.OutOfOrder : TabIndexes.InOrder}
                onKeyDown={(event) => this.handleCardKeyDown(event)}
                onClick={() => this.handleCardClick()}
                aria-label={this.getAriaLabel()}>
                <Card>
                    <CardBody>
                        <div className="ms-Grid-row card-row">
                            <i className="ms-Icon ms-Icon--BoxCheckmarkSolid card-selected-icon ms-sm12 ms-md1 ms-lg1" aria-hidden="true"></i>
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush0  ms-lgPush0  ms-sm5 ms-md5 ms-lg5 removepadding">
                                <Label name="SerialNumberLabel" id={`SerialNumberLabel${index}`} className={"label-header " + pointerClassName}>
                                    {this.props.intl.formatMessage({ id: 'selectConsole.serialNumber', defaultMessage: 'Serial Number' })}
                                </Label>
                                <Label title={this.props.SerialNumber ? this.props.SerialNumber : BLANK_PLACEHOLDER} name="SerialNumberValue" id={`SerialNumberValue${index}`} className={"label-value textOverflow " + pointerClassName}>{this.props.SerialNumber ? this.props.SerialNumber : BLANK_PLACEHOLDER}</Label>
                            </div>
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush0  ms-lgPush0  ms-sm5 ms-md5 ms-lg5 removepadding">
                                <Label name="MfgDateLabel" id={`MfgDateLabel${index}`} className={"label-header " + pointerClassName}>
                                    {this.props.intl.formatMessage({ id: 'selectConsole.mfgDate', defaultMessage: 'MFG date' })}
                                </Label>
                                <Label name="MfgDateValue" id={`MfgDateValue${index}`} className={"label-value " + pointerClassName}>{this.props.ManufacturingDate ? getFormattedDate(this.props.ManufacturingDate) : BLANK_PLACEHOLDER}</Label>
                            </div>
                        </div>
                        <div className="ms-Grid-row card-row">
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush1  ms-lgPush1  ms-sm5 ms-md5 ms-lg5 removepadding">
                                <Label name="SkuNumber" id={`SkuNumber${index}`} className={"label-header " + pointerClassName}>
                                    {this.props.intl.formatMessage({ id: 'selectConsole.DeviceName', defaultMessage: 'SKU Number' })}
                                </Label>
                                <Label name="DeviceNameValue" id={`DeviceNameValue${index}`} className={"label-value " + pointerClassName}> {this.props.DeviceName ? this.props.DeviceName : BLANK_PLACEHOLDER}</Label>
                            </div>
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush1  ms-lgPush1  ms-sm5 ms-md5 ms-lg5 removepadding">
                                {!this.props.hideAttachments &&
                                    <React.Fragment>
                                        <Label name="AttachmentLabel" id={`AttachmentLabel${index}`} className={"label-header " + pointerClassName}>
                                            {this.props.intl.formatMessage({ id: 'selectConsole.attachments', defaultMessage: 'Attachments' })}
                                        </Label>
                                        {this.props.Attachments.length > 0 ?
                                            <AttachmentCard attachments={this.props.Attachments}>
                                                <Link onKeyDown={(event) => this.handleLinkKeyDown(event)} name="AttachmentValue" tabIndex={TabIndexes.OutOfOrder} ref={`tabIndexLink`} id={`AttachmentValue${index}`} onClick={(e) => this.handleAttachmentsClick(e)} className="hover-link label-value">{this.props.Attachments.length}</Link>
                                            </AttachmentCard> : BLANK_PLACEHOLDER}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        <div className="ms-Grid-row card-second-row">
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush1  ms-lgPush1  ms-sm11 ms-md11 ms-lg11 removepadding">
                                <Label name="SkuDescriptionLabel" id={`SkuDescriptionLabel${index}`} className={"label-header " + pointerClassName}>
                                    {this.props.intl.formatMessage({ id: 'selectConsole.SkuDescription', defaultMessage: 'SKU Description' })}
                                </Label>
                                <Label name="SkuDescriptionValue" id={`SkuDescriptionValue${index}`} className={"sku-description-value label-value " + pointerClassName} title={this.props.SkuDescription ? this.props.SkuDescription : BLANK_PLACEHOLDER}>{this.props.SkuDescription ? this.props.SkuDescription : BLANK_PLACEHOLDER}</Label>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    };
}

ConsoleCard.propTypes = propTypes
export default injectIntl(ConsoleCard);