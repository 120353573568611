import * as CoreSagas from '@ec-oem/ec.oem.oa3.ui.core/sagas';
import * as CommonSagas from '@ec-oem/ec.oem.oa3.ui.common/sagas';
import * as XBOXCommonSagas from '../sagas';
import * as DeviceSagas from "../../device/sagas";
import * as TransactionSagas from "../../transaction/sagas";

const sagas = {...CoreSagas, ...CommonSagas, ...XBOXCommonSagas, ...DeviceSagas, ...TransactionSagas};

export const initSagas = (sagaMiddleware) =>{
    Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
}