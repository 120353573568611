import { createSelector } from 'reselect';

export const searchCriteriaSelector = createSelector(
    state => state.app.get("searchCriteria"),
    searchCriteria => searchCriteria ? searchCriteria.toJS() : searchCriteria
);

export const selectedDeviceSelector = createSelector(
    state => state.app.get("selectedDevice"),
    selectedDevice => selectedDevice ? selectedDevice.toJS() : selectedDevice
);