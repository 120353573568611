export const ManageDeviceConstants = {
    DISABLED: 0,
    NOT_STARTED: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    VALIDATION_ERRROR: 4,
    SELECT_CONSOLE: "SELECT_CONSOLE",
    SELECT_STORE_PRODUCTS: "SELECT_STORE_PRODUCTS",
    BUNDLE_CONFIRMATION: "BUNDLE_CONFIRMATION",    
    REWORK: "Rework",
    MANUFACTURING_DATE: "ManufacturingDate"   
}