'use strict';
import { DEVICE_TYPE, DEVICE_IDENTIFIER_TYPE, TRANSACTION_TYPE, PROCESS_TYPE } from '../constants/XBOXConstants';

export class TransactionRequest {
    constructor(transactionRequest) {
        this.TransactionItems = transactionRequest.TransactionItems;
        this.DeviceIdentifierType = transactionRequest.DeviceIdentifierType;
    }
}

export class TransactionItem {
    constructor(detachItem) {
        this.DeviceIdentifier = detachItem.DeviceIdentifier;
        this.SkuNumber = detachItem.SkuNumber;
        this.Products = detachItem.Products.map(product => new TransactionProductRequest({
            ProductId: product.ProductId,
            AvailabiltyId: product.AvailabiltyId,
            ProcessType: product.OperationType === TRANSACTION_TYPE[1] ? PROCESS_TYPE[1] : (product.OperationType === TRANSACTION_TYPE[2] ? PROCESS_TYPE[2] : PROCESS_TYPE[3] )
        }));
    }
}

export class TransactionProductRequest {
    constructor(transactionProductRequest) {
        this.ProductId = transactionProductRequest.ProductId;
        this.AvailabiltyId = transactionProductRequest.AvailabiltyId;
        this.ProcessType = transactionProductRequest.ProcessType;
    }
}

export const deviceToTransactionRequest = (devices) => {
    let transactionItems = [];
    devices.map(device => {
        transactionItems.push(
            new TransactionItem({
                DeviceIdentifier: device.DeviceType === DEVICE_TYPE.SKU ? '' + device.id : device.SerialNumber,
                SkuNumber: device.DeviceName,
                Products: device.ProductsToBeAttached.concat(device.ProductsToBeDetached)
            })
        );
    });
    let devicetypeIdentifier = devices[0].DeviceType === DEVICE_TYPE.SKU ? DEVICE_IDENTIFIER_TYPE.DeviceGroup : DEVICE_IDENTIFIER_TYPE.SerialNumber
    return new TransactionRequest({
        TransactionItems: transactionItems,
        DeviceIdentifierType: devicetypeIdentifier
    });
}