import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { StoreProductFilter } from '../../../common/components';
import { storeProductsSelector, selectedStoreProductsSelector, searchTextSelector } from './ProductSelection.selectors';
import { getPanelStoreProduct, setPanelSelectedStoreProduct } from './ProductSelection.actions';
import { StoreProductCard } from "../../../common/components";
import './ProductSelection.scss';
import Debounce from 'lodash.debounce';
import {
    SearchResult, searchResultInfoItemSelector, SearchResultConstants,
    SearchDefaultSelectionTypes, resetSearchSelection
} from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { SEARCH_TYPES } from '../../../common/constants'
import { isNullOrUndefined } from 'util';
//import { StoreProductList } from '../StoreProductList';

const mappers = {}

const propTypes = {
    initialState: PropTypes.object,    
    intl: intlShape.isRequired
}

class ProductSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.getDefaultSelectionState(),            
            searchText: this.props.searchText ? this.props.searchText : ''
        };
        this.lazySearch = Debounce(this.onChangeText, 1000, true);
    }

    getDefaultSelectionState = (filters) => {
        let searchResultDefaultSelection = this.getDefaultSelections();
        return {
            page: searchResultDefaultSelection[SearchDefaultSelectionTypes.PAGING_OPTION].page,
            pageSize: searchResultDefaultSelection[SearchDefaultSelectionTypes.PAGING_OPTION].pageSize,
            filters: filters ? filters : [],
            items: []
        };
    }

    getDefaultSelectionOnSearchTextChange = () => {
        let searchResultDefaultSelection = this.getDefaultSelections();
        return {
            page: searchResultDefaultSelection[SearchDefaultSelectionTypes.PAGING_OPTION].page,
            pageSize: this.state.selected.pageSize
        };
    }

    componentWillReceiveProps(newProps) {
        if (newProps.selectedProducts) {
            let selected = this.state.selected;
            selected.items = newProps.selectedProducts;
            this.setState({
                selected: selected
            })
        }
    }

    onChangeText = (searchText) => {
        if (searchText !== '') {
            this.setState({
                searchText: searchText,
                selected: this.getDefaultSelectionOnSearchTextChange()
            });
            this.props.getPanelStoreProduct(this.state.selected, searchText);
            this.props.resetSearchSelection(SEARCH_TYPES.PRODUCT_SELECTION, true);
        }
    }    
    
    handleChange = (input) => {
        this.setState({
            selected: input
        })
        this.props.getPanelStoreProduct(input, this.state.searchText);
    }

    getDefaultSelections = () => {
        return {
            [SearchDefaultSelectionTypes.PAGING_OPTION]: {
                page: 1,
                pageSize: 12,
                maxPageSize: 24
            },
            [SearchDefaultSelectionTypes.SELECTED_FILTERS]: null,
            [SearchDefaultSelectionTypes.SELECTED_ITEMS]: null,
            [SearchDefaultSelectionTypes.SORT_OPTION]: null
        }
    }
    
    render() {

        return (
            <div className="store-product-container">
                <div className="ms-Grid">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-row ms-md12 ms-sm12">
                            <StoreProductFilter searchText={this.state.searchText}
                                placeholder={this.props.intl.formatMessage({ id: 'selectStoreProducts.searchStoreProduct', defaultMessage: 'Search Store Products' })}
                                onChange={this.lazySearch}></StoreProductFilter>
                        </div>
                        <div className="ms-Grid-row ms-md12 ms-sm12">
                            <SearchResult searchName={SEARCH_TYPES.PRODUCT_SELECTION}
                                items={this.props.products != null ? this.props.products.Records : []}
                                searchCriteria={[]}
                                totalItemsCount={this.props.products != null ? this.props.products.TotalCount : 0}
                                ResultIteratorComponent={StoreProductCard }
                                handleChange={(selected) => this.handleChange(selected)}
                                hideResultHeader={this.props.products ? false : true}
                                mappers={mappers}
                                allowItemSelection={true}
                                defaultSelections={this.getDefaultSelections()}
                            >
                            </SearchResult>                        
                        </div>
                    </div>
                </div>               
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        products: storeProductsSelector(state),
        selectedProducts: selectedStoreProductsSelector(state),
        searchText: searchTextSelector(state),
        selectedItems: searchResultInfoItemSelector(state, SEARCH_TYPES.PRODUCT_SELECTION, SearchResultConstants.SELECTED_ITEMS)
    };
}


const mapDispatchToProps = (dispatch) => {
    return {        
        getPanelStoreProduct: bindActionCreators(getPanelStoreProduct, dispatch),
        setPanelSelectedStoreProduct: bindActionCreators(setPanelSelectedStoreProduct, dispatch),
        resetSearchSelection: bindActionCreators(resetSearchSelection, dispatch),        
    }
}

ProductSelection.propTypes = propTypes;

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductSelection));