import * as React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { TRANSACTION_STATUS, DEVICE_TYPE } from '../../constants';

const propTypes = {
    props : PropTypes.any
}

const ProductStatus = (props) => {
    const getStatusClassName = () => {
        let setclassname = "single-status";
        if(props.deviceType === DEVICE_TYPE.CONSOLE) {
            setclassname = "console-status-selection"
        }
        else {
            if (props.itemLength === 2) {
                setclassname = "two-status";
            }
            else if (props.itemLength > 2) {
                setclassname = "status-more-then-two"
            }
        }
        return setclassname;
    }

    let statusclass = getStatusClassName();
    let statusIndex = TRANSACTION_STATUS.findIndex(item => item.value === props.Status);
    return (
        statusIndex > -1 &&
        <div className={statusclass} >
            <span className="label-value">{TRANSACTION_STATUS[statusIndex] && TRANSACTION_STATUS[statusIndex].text}</span>
            <span className="status-count-label">{props.Count}</span>
        </div>
    );
}

ProductStatus.propTypes = propTypes;
export default injectIntl(ProductStatus);