'use strict';
import { transactionDetailsToDevice } from './Device'
import { productTransactionsInfoMapper } from './ProductTransactionInfo';



export class DeviceProductTransaction {
  constructor(device, deviceProductTransaction) {
    this.Device = device ? device : null;
    this.ProductTransactions = deviceProductTransaction ? deviceProductTransaction : [];
  }
}
export const deviceProductTransactionMapper = (deviceProductDetails) => {
  let deviceDetails = []
  if (deviceProductDetails) {
    deviceProductDetails.map((detail, ind) => {
      deviceDetails.push(new DeviceProductTransaction(
        transactionDetailsToDevice(detail.Device),
        productTransactionsInfoMapper(detail.ProductTransactions)
      ))
    });
  }
  return deviceDetails;
}