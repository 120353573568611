import * as React from 'react';
import PropTypes from 'prop-types';
import { Label, Link } from 'office-ui-fabric-react';
import { Card, CardBody } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { TabIndexes, KeyBoardKeys } from '@ec-oem/ec.oem.oa3.ui.common/constants';
import { BLANK_PLACEHOLDER } from '../../constants/XBOXConstants';
import { injectIntl } from 'react-intl';
import { Device } from '../../../common/models';
import { AttachmentCard } from '../AttachmentCard';
import { KeyCodes } from '@uifabric/utilities';
import ReactDOM from 'react-dom';
import { DEVICE_TYPE } from '../../constants';

const propTypes = {
    DeviceName: PropTypes.string,
    disableSelection: PropTypes.bool,
    disabled: PropTypes.bool,
    Rework: PropTypes.bool,
    Attachments: PropTypes.any,
    hideAttachments:PropTypes.bool,
    selected: PropTypes.bool,
    SkuDescription: PropTypes.string
}

class SKUCard extends React.Component {
    handleCardClick = () => {
        if (!this.props.disableSelection && !this.props.disabled) {
            let selectedItem = new Device({
                id: this.props.id,
                DeviceType: DEVICE_TYPE.SKU,
                DeviceName: this.props.DeviceName,
                ProductFamily: this.props.ProductFamily,
                Rework: this.props.Rework,
                ReworkCount: this.props.ReworkCount,
                ConsoleVolume: this.props.ConsoleVolume,
                Attachments: this.props.Attachments,
                Status: this.props.Status,
                disabled: this.props.disabled,
                SkuDescription: this.props.SkuDescription
            }, this.props.selected);
            this.props.onItemClick && this.props.onItemClick(selectedItem);
        }
    }

    handleAttachmentsClick = (e) => {
        e.stopPropagation();
    }

    handleLinkKeyDown = (e) => {
        if (e.keyCode === KeyCodes.enter || e.keyCode === KeyCodes.space) {
            e.preventDefault();
        }
        e.stopPropagation();
    }

    handleCardKeyDown = (e) => {
        if (e.keyCode === KeyBoardKeys.Enter || e.keyCode === KeyBoardKeys.Space) {
            e.preventDefault();
            this.handleCardClick();
        }
        else if (e.keyCode === 40) {
            e.preventDefault();
            let linkNode = ReactDOM.findDOMNode(this.refs['tabIndexLink']);
            if(linkNode){
                linkNode.focus();
            }
            e.stopPropagation();
            return "";
        }
    }

    getCardClassName = () => {
        let cardClassName = 'search-card sku-card ms-Grid-col ms-sm12 ms-md12 ms-lg4 ms-xl4 removepadding ';
        if (!this.props.disableSelection) {
            cardClassName += this.props.disabled ? 'disable-card ' : 'pointer-cursor ';
            if (!this.props.disabled && this.props.selected) {
                cardClassName += 'selected';
            }
        }
        return cardClassName;
    }

    getAriaLabel = () => {
        let ariaLabel = this.props.intl.formatMessage({ id: 'selectConsole.selectCard', defaultMessage: 'Click tab for next card. Click down arrow for attachement.' });
        if (!this.props.disableSelection && !this.props.disabled && this.props.selected) {
            ariaLabel += this.props.intl.formatMessage({ id: 'selectConsole.selectedCard', defaultMessage: 'This card is Selected' });
        }
        else {
            ariaLabel += this.props.intl.formatMessage({ id: 'selectConsole.notSelectedCard', defaultMessage: 'This card is not Selected' });
        }
        return ariaLabel;
    }

    getPointerClassName = () => {
        if (!this.props.disableSelection) {
            return "pointer-cursor"
        }
        else
            return ""
    }

    render() {
        const { index } = this.props;
        let pointerClassName = this.getPointerClassName();
        return (
            <div id={`SkuCard${index}`} className={this.getCardClassName()}
                tabIndex={this.props.disableSelection || this.props.disabled ? TabIndexes.OutOfOrder : TabIndexes.InOrder}
                onKeyDown={(event) => this.handleCardKeyDown(event)}
                onClick={() => this.handleCardClick()}
                aria-label={this.getAriaLabel()}>
                <Card>
                    <CardBody>
                        <div className="ms-Grid-row card-row">
                            <i className="ms-Icon ms-Icon--BoxCheckmarkSolid card-selected-icon ms-sm12 ms-md12 ms-lg1" aria-hidden="true"></i>
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush1  ms-lgPush0 ms-xlPush0  ms-sm5 ms-md5 ms-lg5 ms-xl5 removepadding">
                                <Label name="SKUPartNumber" id={`SKUPartNumber${index}`} className={"label-header " + pointerClassName} >
                                    {this.props.intl.formatMessage({ id: 'selectConsole.skuNumber', defaultMessage: 'SKU Part Number' })}
                                </Label>
                                <Label title={this.props.DeviceName ? this.props.DeviceName : BLANK_PLACEHOLDER} name="DeviceNameValue" id={`DeviceNameValue${index}`} className={"label-value textOverflow " + pointerClassName}>{this.props.DeviceName ? this.props.DeviceName : BLANK_PLACEHOLDER}</Label>
                            </div>
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush1  ms-lgPush0 ms-xlPush0  ms-sm5 ms-md5 ms-lg5 ms-xl5 removepadding">
                                <Label name="ConsoleVolumeLabel" id={`ConsoleVolumeLabel${index}`} className={"label-header " + pointerClassName}>
                                    {this.props.intl.formatMessage({ id: 'selectConsole.consoleVolume', defaultMessage: 'Console Volume' })}
                                </Label>
                                <Label name="ConsoleVolumeValue" id={`ConsoleVolumeValue${index}`} className={"label-value " + pointerClassName}>{this.props.ConsoleVolume ? this.props.ConsoleVolume : BLANK_PLACEHOLDER}</Label>
                            </div>
                        </div>
                        <div className="ms-Grid-row card-row">
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush1  ms-lgPush1 ms-xlPush1  ms-sm5 ms-md5 ms-lg5 ms-xl5 removepadding">
                                <Label name="SkuReworkLabel" id={`SkuReworkLabel${index}`} className={"label-header " + pointerClassName}>
                                    {this.props.intl.formatMessage({ id: 'selectConsole.skuRework', defaultMessage: 'Transformation' })}
                                </Label>
                                <Label name="SkuReworkValue" id={`SkuReworkValue${index}`} className={"label-value " + pointerClassName}>{this.props.Rework !== undefined ? (this.props.Rework ? 'Yes' : 'No') : BLANK_PLACEHOLDER}</Label>
                            </div>
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush1  ms-lgPush1 ms-xlPush1  ms-sm5 ms-md5 ms-lg5 ms-xl5 removepadding">
                                {!this.props.hideAttachments &&
                                    <React.Fragment>
                                        <Label name="AttachmentLabel" id={`AttachmentLabel${index}`} className={"label-header " + pointerClassName}>
                                            {this.props.intl.formatMessage({ id: 'selectConsole.attachments', defaultMessage: 'Attachments' })}
                                        </Label>
                                        {this.props.Attachments.length > 0 ?
                                            <AttachmentCard attachments={this.props.Attachments}>
                                                <Link onKeyDown={(event) => this.handleLinkKeyDown(event)} tabIndex={TabIndexes.OutOfOrder} ref={`tabIndexLink`} 
                                                    name="AttachmentValue" id={`AttachmentValue${index}`} 
                                                    aria-label={this.props.intl.formatMessage({ id: 'app.attachmentArialabel', defaultMessage: "" })} 
                                                    onClick={(e) => this.handleAttachmentsClick(e)} className="hover-link label-value card-link-color">{this.props.Attachments.length}</Link>
                                            </AttachmentCard> : BLANK_PLACEHOLDER}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        <div className="ms-Grid-row card-second-row">
                            <div className="ms-Grid-col ms-smPush1 ms-mdPush1  ms-lgPush1 ms-xlPush1  ms-sm11 ms-md11 ms-lg11 ms-xl11 removepadding">
                                <Label name="SkuDescriptionLabel" id={`SkuDescriptionLabel${index}`} className={"label-header " + pointerClassName}>
                                    {this.props.intl.formatMessage({ id: 'selectConsole.SkuDescription', defaultMessage: 'SKU Description' })}
                                </Label>
                                <Label name="SkuDescriptionValue" id={`SkuDescriptionValue${index}`} className={"sku-description-value label-value " + pointerClassName} title={this.props.SkuDescription ? this.props.SkuDescription : BLANK_PLACEHOLDER}>{this.props.SkuDescription ? this.props.SkuDescription : BLANK_PLACEHOLDER}</Label>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    };
}

SKUCard.propTypes = propTypes
export default injectIntl(SKUCard);

