'use strict';
import {TRANSACTION_TYPE} from '../constants/XBOXConstants';

export class Product {
  constructor(storeProductData, selected, id) {    
    this.id = id ? id : (storeProductData.id ? storeProductData.id : storeProductData.ProductId);
    this.selected = selected;
    this.value = storeProductData.Title ? storeProductData.Title : ''
    this.label = storeProductData.ProductId ? storeProductData.ProductId : '';
    this.ProductId = storeProductData.ProductId ? storeProductData.ProductId : '';
    this.ProductFamily = storeProductData.ProductFamily ? storeProductData.ProductFamily : '';
    this.SKU = storeProductData.SKU ? storeProductData.SKU : null;
    this.AvailabiltyId = storeProductData.AvailabiltyId ? storeProductData.AvailabiltyId : null;
    this.RedemptionStartDate = storeProductData.RedemptionStartDate ? storeProductData.RedemptionStartDate : '';
    this.RedemptionEndDate = storeProductData.RedemptionEndDate ? storeProductData.RedemptionEndDate : '';
    this.Title = storeProductData.Title ? storeProductData.Title : '';
    this.Icon = storeProductData.Icon ? storeProductData.Icon : '';
    this.Status = storeProductData.Status ? storeProductData.Status : '';
    this.ProcessType = storeProductData.ProcessType ? storeProductData.ProcessType : '';
    this.FulfilmentId = storeProductData.FulfilmentId ? storeProductData.FulfilmentId : '';
    this.OperationType = storeProductData.OperationType ? storeProductData.OperationType : (storeProductData.ProcessType ? TRANSACTION_TYPE[storeProductData.ProcessType] : null);    
  }
}
export const productMapper = (attachments) => {
  let products = [];
  products = (attachments !== null && attachments.length > 0) ?
    attachments.map((attachment, key) => {     
      return attachment? new Product({
        ProductId: attachment.ProductId,
        ProductFamily: attachment.ProductFamily,
        SKU: attachment.SKU,
        AvailabiltyId: attachment.AvailabiltyId,
        RedemptionStartDate: attachment.RedemptionStartDate,
        RedemptionEndDate: attachment.RedemptionEndDate,
        Title: attachment.Title,
        Icon: attachment.Icon,
        Status: attachment.Status,
        ProcessType: attachment.ProcessType,
        FulfilmentId: attachment.FulfilmentId,
      }, false, key + 1): null;
    }) : []
  return products;
}